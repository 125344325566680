import React from "react";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CheckCircle, Edit } from "@mui/icons-material/";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { red } from "@mui/material/colors";

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function TableContent(props) {
  const { columns, rows, page, setCurrentPage } = props;
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: "#D9D9D9" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={row.id.toString() + column.id}
                      align={column.align}
                    >
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                      {column.label === "Modificar" ? (
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#6D88A6", color: "white" }}
                          onClick={() => props.setClientEditing(row.id)}
                        >
                          <Edit />
                        </Button>
                      ) : (
                        ""
                      )}
                      {column.id === "active" && row.active && (
                        <IconButton>
                          <CheckCircle color="success" />
                        </IconButton>
                      )}
                      {column.id === "active" && !row.active && (
                        <IconButton>
                          <HighlightOffIcon style={{ color: red[500] }} />
                        </IconButton>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Cantidad de registros por página"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.count}
        rowsPerPage={props.rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
