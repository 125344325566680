import React, { useState, useEffect } from "react";
import { Button, Modal as ModalMaterial, Fade, Grid, TextField, MenuItem, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { AlertDialog } from "../../../../shared/components/AlertDialog";
import { ModalLayout } from "../../../../shared/components/ModalLayout";
import { TabPanel } from "../../../../shared/components/TabPanel";
import { UseFetch } from "../../../../shared/hooks/UseFetch";
import { ResponsePaginator } from "app/types/common/ResponsePaginator";

export const Modal = (props: any) => {
  const [check, setCheck] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { getFetch } = UseFetch("users/roles/");

  const [nameUser, setNameUser] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [role, setRole] = useState("-1");
  const [roles, setRoles] = useState([] as any[]);



  const defaultPassword = '*********';

  const fetchUser = UseFetch("users/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const getRoles = () => {
    getFetch().then((response: ResponsePaginator) => {
      setRoles(response.data.results);
    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const fetchUpdateUsers = UseFetch(`users/${props.editing}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const fetchListClients = UseFetch("users/");

  const fetchById = UseFetch("users/" + props.editing + "/");

  const handleClose = () => {
    closeView();
    props.setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (props.editing) {
      searchById(props.editing);
      props.setOpen(true);
    }
  }, [props.editing]);


  const searchById = (id: any) => {
    fetchById.getFetch().then((response: any) => {
      if (response.data) {
        const { data } = response;
        setNameUser(data.username);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setpassword(defaultPassword);
        setRole(data.role.id);
      }
    });
  };

  const isValid = () => {
    if (nameUser && nameUser !== "" &&
      firstName && firstName !== "" &&
      lastName && lastName !== "" &&
      email && email !== "" &&
      role && role !== "-1") {
      if (!props.editing) {
        if (password) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const refreshDataMaterials = () => {
    fetchListClients.getFetch().then((response: any) => {
      props.setMaterialsData(response.data);
      props.setCurrentPage(0);
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);
    let params: any = {
      username: nameUser.trim(),
      email: email.trim(),
      first_name: firstName,
      last_name: lastName,
      role: role,
    };
    if (props.editing) {
      if (password !== defaultPassword) {
        params.pwd = password;
      }

      fetchUpdateUsers.getFetch(params).then(
        (response: any) => {
          if (response.data.type?.toLowerCase() != "error") {
            setTypeAlert("success");
            setContent(`Se ha editado con éxito.`);
            closeView();
          } else {
            props.setOpen(true);
            setTypeAlert("error");
            setContent(
              response.data.message
                ? response.data.message
                : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
                }.`
            );
            setOpenDialog(true);
          }
        },
        (error) => {
          props.setOpen(true);
          setTypeAlert("error");
          setContent(
            error.data.message
              ? error.data.message
              : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
              }.`
          );
        }
      );
    } else {
      await fetchUser
        .getFetch({ ...params, password })
        .then(
          (response: any) => {
            if (response.data.type?.toLowerCase() != "error") {
              setTypeAlert("success");
              setContent(`Se ha editado con éxito.`);
              closeView();
            } else {
              props.setOpen(true);
              setTypeAlert("error");
              setContent(
                response.data.message
                  ? response.data.message
                  : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
                  }.`
              );
              setOpenDialog(true);
            }
          },
          (error) => {
            props.setOpen(true);
            setTypeAlert("error");
            setContent(
              error.data.message
                ? error.data.message
                : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
                }.`
            );
            setOpenDialog(true);
          }
        );
    }
    props.setRefreshUsers(Date.now())
  };

  const closeView = () => {
    onResetForm();
    // refreshDataMaterials();
    props.setOpen(false);
  };

  const onResetForm = () => {
    props.setEditing(null);
    setNameUser('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setpassword('');
    setRole('');
    setFormSubmitted(false);
  };


  const isError = (field: string) => {
    if (formSubmitted) {
      return (field === '' || !field)
    } else {
      return false;
    }
  }

  return (
    <>
      <AlertDialog
        type={typeAlert}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <ModalMaterial open={props.open} onClose={handleClose} closeAfterTransition>
        <Fade in={props.open}>
          <div className="modal-container">
            <ModalLayout
              title={
                props.editing
                  ? "Editar de materiales"
                  : "Alta de usuario"
              }
              width={"60%"}
              handleClose={handleClose}
            >
              <TabPanel>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={2} sx={{ minHeight: 100 }}>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <TextField
                        label="Nombre"
                        type="text"
                        placeholder=""
                        fullWidth
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        error={isError(firstName)}
                        helperText={false}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <TextField
                        label="Apellido"
                        type="text"
                        placeholder=""
                        fullWidth
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        error={isError(lastName)}
                        helperText={false}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <TextField
                        label="Nombre de usuario"
                        type="text"
                        placeholder=""
                        fullWidth

                        value={nameUser}
                        onChange={(e) => setNameUser(e.target.value)}
                        error={isError(nameUser)}
                        helperText={false}
                        inputProps={{
                          maxLength: 100,
                          autoComplete: "off",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <TextField
                        label="Correo electronico"
                        type="text"
                        placeholder=""
                        fullWidth
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={isError(email)}
                        helperText={false}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <TextField
                        label="Contraseña"

                        placeholder=""
                        fullWidth

                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                        error={isError(password)}
                        helperText={false}
                        inputProps={{
                          maxLength: 100,
                          autoComplete: "off",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <FormControl
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-label">
                          Seleccionar Rol
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select-id"
                          fullWidth
                          name="rol"
                          placeholder="Seleccionar Rol"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          error={isError(role)}
                          inputProps={{ maxLength: 100 }}
                        >
                          {roles?.map((role: any) =>
                            <MenuItem value={role.id}>{role.description}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <Grid
                container
                spacing={2}
                sx={{ mb: 2, mt: 1 }}
                style={{ bottom: "5%" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: "#afafaf", color: "white" }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!isValid()}
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </ModalLayout>
          </div>
        </Fade>
      </ModalMaterial>
    </>
  );
};
