import { Column } from 'shared/components/Table/Table.type';

export const columns: Column[] = [
    {
        id: "name",
        label: "Nombre del Vehículo",
        minWidth: 170,
        align: "center"
      },
      {
        id: "",
        label: "Modificar",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
      },
];