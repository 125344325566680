export const KeyHandles = (supportTwoOrMoreZeroInLeft = false) => {
  const keyDownOnlyNumberHandle = (event) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    let isPositiveNumber = true;
    if (!supportTwoOrMoreZeroInLeft) {
      isPositiveNumber =
        /^\d$/.test(event.key) && event.target.value !== "0";
    }
    else {
      isPositiveNumber =
        /^\d$/.test(event.key);
    }

    if (!isPositiveNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  return { keyDownOnlyNumberHandle };
};
