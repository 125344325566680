import { Column } from 'shared/components/Table/Table.type';

export const columns: Column[] = [
    {
        id: "name",
        label: "Nombre",
        minWidth: 170,
        align: "center",
    },
    {
        id: "active",
        label: "Activo",
        minWidth: 170,
        align: "center",
    },
    {
        id: "",
        label: "Modificar",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
    }
];