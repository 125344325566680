import React, { useState } from 'react'
import { NavBar } from './NavBar'

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const handleLogout = () => {
    setIsLoggedIn(false)
  }
  return (
    <NavBar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
  )
}
export default Header;