import { useState, useEffect } from "react";
import { TextField, Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { downloadFile } from "../../../../shared/hooks/DownloadFile";
import { Delete, Search } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { UseFetch } from "../../../../shared/hooks/UseFetch";
import { DateRange } from "../../../../shared/components/DateRange";
import { AlertDialog } from "../../../../shared/components/AlertDialog";
import { ModalVisibleColumn } from "../ModalVisibleColumn/ModalVisibleColumn";
import VisibilityIcon from '@mui/icons-material/Visibility';

const formatDate = (currentDate) => {
  if (currentDate == "") {
    return "";
  }

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const PurchaseHeader = ({
  setOpen,
  setFilter,
  filters,
  data,
  filtersActive,
  setRefreshList,
  selectedOptions,
  handleChange,
  options
}) => {
  const [mostrarTotal, setMostrarTotal] = useState(false);
  const [labelMostrarTotal, setLabelMostrarTotal] = useState('Mostrar total');

  const [filtersActivePrev, setFiltersActivePrev] = useState([]);

  useEffect(() => {
    changeFilter()
  }, [filtersActive])

  const changeFilter = () => {
    setFiltersActivePrev(filtersActive)
    filtersActivePrev.forEach(element => {
      if (!filtersActive.includes(element)) {
        cleanFilters(element);
      }
    });
  }

  const cleanFilters = (filterCleaned) => {
    const emptyDate = {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    };


    switch (filterCleaned) {
      case 'client_short_name':
        setClientSelectedFilterId(-1);
        setClientSelectedFilter();
        setKeyAutocomplete(Math.random());
        setFilter({ ...filters, client_id: -1 });
        break;
      case 'formated_date':
        setDateRange(emptyDate);
        setFilter({ ...filters, ...emptyDate });
        break;
      case 'outbound_reference_number':
        setOutboundReferenceNumberFiltered();
        setKeyOutboundReferenceNumberFiltered(Math.random());
        delete filters.outbound_reference_number;
        setFilter({ ...filters });
        break;
      case 'ticket':
        setTicketFiltered();
        setKeyTicketFiltered(Math.random());
        delete filters.ticket;
        setFilter({ ...filters });
        break;
      case 'control_number':
        setControlNumberFiltered();
        setKeyControlNumberFiltered(Math.random());
        delete filters.control_number;
        setFilter({ ...filters });
        break;
      case 'material_name':
        setMaterials(allMaterials);
        setKeyMaterial(Math.random());
        setMaterialSelectedFilter();
        setMaterialSelectedFilterId();
        delete filters.material_id;
        setFilter({ ...filters });
        break;
      case 'weight':
        setKeyWeightFiltered(Math.random());
        setWeightFiltered();
        delete filters.weight;
        setFilter({ ...filters });
        break;
      case 'price':
        setPriceFiltered();
        setKeyPriceFiltered(Math.random())
        delete filters.price;
        setFilter({ ...filters });
        break;
      case 'vehicle_name':
        setVehicleSelectedFilter(null);
        setVehicleSelectedFilterId(-1)
        delete filters.vehicle_id;
        setFilter({ ...filters });
        break;
      case 'trailer_name':
        setTrailerSelectedFilter(null);
        setTrailerSelectedFilterId(-1)
        delete filters.trailer_id;
        setFilter({ ...filters });
        break;
    }

    initTotal();
    setRefreshList(Math.random())
  }

  const getFilters = (filter) => {

    let filterString = "init=0";
    if (clientSelectedFilterId && clientSelectedFilterId != -1) {
      filterString = "&client_id=" + clientSelectedFilterId + "";
    }

    if (
      dateRange?.startDate &&
      dateRange?.startDate != "" &&
      dateRange?.endDate &&
      dateRange?.endDate != ""
    ) {
      filterString +=
        "&start_date=" +
        formatDate(dateRange?.startDate) +
        "&end_date=" +
        formatDate(dateRange?.endDate);
    }

    if (materialSelectedFilterId && materialSelectedFilterId !== -1) {
      filterString += "&material_id=" + materialSelectedFilterId;
    }

    if (vehicleSelectedFilterId && vehicleSelectedFilterId !== -1) {

      filterString += "&vehicle_id=" + vehicleSelectedFilterId;
    }

    if (trailerSelectedFilterId && trailerSelectedFilterId !== -1) {

      filterString += "&trailer_id=" + trailerSelectedFilterId;
    }

    if (filter.price) {
      filterString += "&price=" + filter.price;
    }

    if (filter.ticket) {
      filterString += "&ticket=" + filter.ticket;
    }

    if (filter.control_number) {
      filterString += "&control_number=" + filter.control_number;
    }

    if (filter.weight) {
      filterString += "&weight=" + filter.weight;
    }

    if (filter.outbound_reference_number) {
      filterString +=
        "&outbound_reference_number=" + filter.outbound_reference_number;
    }

    return filterString;
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
    key: "selection",
  });
  const [total, setTotal] = useState(0);
  const [clients, setClients] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [trailers, setTrailers] = useState([]);

  const [clientSelectedFilterId, setClientSelectedFilterId] = useState(-1);
  const [clientSelectedFilter, setClientSelectedFilter] = useState();
  const [materialSelectedFilterId, setMaterialSelectedFilterId] = useState(-1);
  const [materialSelectedFilter, setMaterialSelectedFilter] = useState();

  const [vehicleSelectedFilterId, setVehicleSelectedFilterId] = useState(-1);
  const [vehicleSelectedFilter, setVehicleSelectedFilter] = useState();

  const [trailerSelectedFilterId, setTrailerSelectedFilterId] = useState(-1);
  const [trailerSelectedFilter, setTrailerSelectedFilter] = useState();

  const fetchListClients = UseFetch("clients/?page_size=0");
  const fetchVehicles = UseFetch("vehicles/?page_size=0");
  const fetchTrailers = UseFetch("trailers/?page_size=0");
  

  const [keyAutomComplete, setKeyAutocomplete] = useState(Math.random());
  const [keyMaterial, setKeyMaterial] = useState(Math.random());
  const [allMaterials, setAllMaterials] = useState([]);
  const { getFetch } = UseFetch("materials/?page_size=0");

  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [ticketFiltered, setTicketFiltered] = useState();
  const [controlNumberFiltered, setControlNumberFiltered] = useState();
  const [weightFiltered, setWeightFiltered] = useState();
  const [priceFiltered, setPriceFiltered] = useState()
  const [openVisibleColumnModal, setOpenVisibleColumnModal] = useState(false);


  const [outboundReferenceNumberFiltered, setOutboundReferenceNumberFiltered] =
    useState();

  const [keyTicketFiltered, setKeyTicketFiltered] = useState(Math.random());
  const [keyControlNumberFiltered, setKeyControlNumberFiltered] = useState(
    Math.random()
  );
  const fetchTotal = UseFetch(`purchases/total?${getFilters(filters)}`);
  const [keyWeightFiltered, setKeyWeightFiltered] = useState(Math.random());
  const [keyPriceFiltered, setKeyPriceFiltered] = useState(Math.random());
  const [
    keyOutboundReferenceNumberFiltered,
    setKeyOutboundReferenceNumberFiltered,
  ] = useState(Math.random());

  useEffect(() => {
    initVehicles();
    initTrailers();
    fetchListClients.getFetch().then((response) => {
      setClients(response.data);
    });
    getFetch().then((response) => {
      setAllMaterials(response.data);
      setMaterials(response.data);
    });
  }, []);

  useEffect(() => {
    initTotal();
  }, [data]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const initVehicles = () => {
    fetchVehicles.getFetch().then((response) => {
      setVehicles(response.data);
    });
  };

  const initTrailers = () => {
    fetchTrailers.getFetch().then((response) => {
      setTrailers(response.data);
    });
  };

  const initTotal = () => {
    fetchTotal.getFetch().then((response) => {
      let total = '$0.00';
      if (response && response?.data?.total) {
        total = parseFloat(response.data.total).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        })
      }
      setTotal(total);
    });
  };

  const handleApplyFilters = (event) => {
    let filters = {};

    if (dateRange.startDate && dateRange.endDate) {
      filters = { ...dateRange };
    }
    if (clientSelectedFilterId && clientSelectedFilterId !== -1) {
      filters.client_id = clientSelectedFilterId;
    }

    if (vehicleSelectedFilterId && vehicleSelectedFilterId !== -1) {
      filters.vehicle_id = vehicleSelectedFilterId;
    }

    if (trailerSelectedFilterId && trailerSelectedFilterId !== -1) {
      filters.trailer_id = trailerSelectedFilterId;
    }

    if (materialSelectedFilterId && materialSelectedFilterId !== -1) {
      filters.material_id = materialSelectedFilterId;
    }

    if (ticketFiltered) {
      filters.ticket = ticketFiltered;
    }

    if (controlNumberFiltered) {
      filters.control_number = controlNumberFiltered;
    }

    if (weightFiltered) {

      filters.weight = weightFiltered;
    }

    if (priceFiltered) {

      filters.price = priceFiltered;
    }

    if (outboundReferenceNumberFiltered) {
      filters.outbound_reference_number = outboundReferenceNumberFiltered;
    }

    setFilter(filters);
  };

  const handleCleanFilters = (event) => {
    const emptyDate = {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    };
    setMaterials(allMaterials);
    setDateRange(emptyDate);
    setClientSelectedFilterId(-1);
    setFilter({ ...emptyDate, clientId: -1 });
    setKeyAutocomplete(Math.random());
    setKeyMaterial(Math.random());
    setClientSelectedFilter();
    setMaterialSelectedFilter();
    setMaterialSelectedFilterId();
    setPriceFiltered();
    setVehicleSelectedFilter(null);
    setVehicleSelectedFilterId(-1)
    setTrailerSelectedFilter(null);
    setTrailerSelectedFilterId(-1)


    setTicketFiltered();
    setControlNumberFiltered();
    setWeightFiltered();
    setOutboundReferenceNumberFiltered();

    setKeyTicketFiltered(Math.random());
    setKeyControlNumberFiltered(Math.random());
    setKeyWeightFiltered(Math.random());
    setKeyPriceFiltered(Math.random())
    setKeyOutboundReferenceNumberFiltered(Math.random());

    initTotal();
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const isDisableExportBotton = () => {
    if ((filters.client_id && filters.client_id !== -1 || filters.material_id && filters.material_id !== -1) && data.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDownloadFile = () => {
    downloadFile(`purchases/report/?${getFilters(filters)}`)
      .then()
      .catch((error) => {
        setContent(
          "Ocurrio un error al generar el reporte de excel, favor de contactar con el administrador."
        );
        setOpenDialog(true);
      });
  };

  const handleSelect = (e, value) => {
    setMaterials(allMaterials);
    setMaterialSelectedFilterId(-1);
    setMaterialSelectedFilter();
    setClientSelectedFilterId(null);
    setClientSelectedFilter(null);

    if (value) {
      setClientSelectedFilter(value);
      setClientSelectedFilterId(value.id);
      const client = clients.find((x) => x.id == value.id);
      let materialsClient = client.materials.map((item) => item.material);
      setMaterials(materialsClient);
    } else {
      setClientSelectedFilterId(-1);
    }



    setMaterialSelectedFilterId(-1);
    setMaterialSelectedFilter();
    setKeyMaterial(Math.random());
  };

  const handleMaterialSelect = (e, value) => {
    if (value) {
      setMaterialSelectedFilterId(value.id);
    } else {
      setMaterialSelectedFilterId(-1);
    }
    setMaterialSelectedFilter(value);
  };


  const handleChangeVehicle = (e, value) => {

    if (value) {

      setVehicleSelectedFilterId(value.id)

    } else {
      setVehicleSelectedFilterId(-1)

    }

    setVehicleSelectedFilter(value);

  };

  const handleChangeTrailer = (e, value) => {

    if (value) {
      console.log("Aqui andamos")
      setTrailerSelectedFilterId(value.id)

    } else {
      setTrailerSelectedFilterId(-1)

    }

    setTrailerSelectedFilter(value);
  };

  const handleDateChange = (e) => { };



  const handleShowFilters = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  const handleMostrarTotal = () => {
    if (mostrarTotal) {
      setMostrarTotal(!mostrarTotal);
      setLabelMostrarTotal('Mostrar total');
      return;
    }
    setLabelMostrarTotal('Ocultar total');
    setMostrarTotal(!mostrarTotal);
  }

  const handleOpenVisibleColumnsModal = () => {
    setOpenVisibleColumnModal(true)
  }

  return (
    <>
      <AlertDialog
        type={"error"}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />

      <ModalVisibleColumn open={openVisibleColumnModal} setOpen={setOpenVisibleColumnModal}
        selectedOptions={selectedOptions}
        handleChange={handleChange}
        options={options}

      />

      <Grid container justifyContent="space-between">
        <Grid container justifyContent="space-between">
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item style={{ marginLeft: 8 }}>
              <Tooltip title="Aplicar filtros" placement="top">
                <Button
                  variant="contained"
                  sx={{ marginRight: 1 }}
                  color="primary"
                  onClick={handleApplyFilters}
                >
                  <Search />
                </Button>
              </Tooltip>
              <Tooltip title="Limpiar filtros" placement="top">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginRight: 1 }}
                  onClick={handleCleanFilters}
                >
                  <Delete />
                </Button>
              </Tooltip>
              <Tooltip title="Columnas visibles" placement="top">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginRight: 1 }}
                  onClick={handleOpenVisibleColumnsModal}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              sx={{ marginRight: 1 }}
              style={{
                alignSelf: "center",
                display: mostrarTotal ? "" : "none",
              }}
            >
              Total: {total}
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: '10px' }}>
              <Tooltip title={labelMostrarTotal} placement="top" >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMostrarTotal}
                >
                  {labelMostrarTotal}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sx={{ marginRight: 1 }}>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Registrar entrada
              </Button>
            </Grid>
            <Tooltip title="Es necesario filtrar por proveedor para descargar reporte" placement="top">
              <Grid item>
                <Button
                  variant="contained"
                  color="sixth"
                  onClick={handleDownloadFile}
                  disabled={isDisableExportBotton()}
                >
                  Descargar reporte
                </Button>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ width: "100%", marginTop: 10, display: "flex" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("client_short_name") ? "" : "none",
            }}
          >
            <Autocomplete
              style={{ width: "100%" }}
              disablePortal
              disabled={false}
              key={keyAutomComplete}
              id="combo-box-demo"
              getOptionLabel={(option) => option.short_name ?? ""}
              onChange={handleSelect}
              options={clients}
              value={clientSelectedFilter}
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%" }}
                  {...params}
                  label="Seleccione un proveedor:"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("formated_date") ? "" : "none",
            }}
          >
            <Tooltip title="Seleccionar rango de fechas" placement="top">
              <div>
                <DateRange
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  handleDateChange={handleDateChange}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("outbound_reference_number")
                ? ""
                : "none",
            }}
          >
            <TextField
              style={{ width: "100%" }}
              label="Buscar folio salida"
              onChange={(e) =>
                setOutboundReferenceNumberFiltered(e.target.value)
              }
              value={outboundReferenceNumberFiltered}
              key={keyOutboundReferenceNumberFiltered}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("ticket") ? "" : "none",
            }}
          >
            <TextField
              style={{ width: "100%" }}
              label="Buscar folio ticket"
              onChange={(e) => setTicketFiltered(e.target.value)}
              value={ticketFiltered}
              key={keyTicketFiltered}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("control_number") ? "" : "none",
            }}
          >
            <TextField
              style={{ width: "100%" }}
              label="Buscar número de control"
              onChange={(e) => setControlNumberFiltered(e.target.value)}
              value={controlNumberFiltered}
              key={keyControlNumberFiltered}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("material_name") ? "" : "none",
            }}
          >
            <Autocomplete
              style={{ width: "100%" }}
              disablePortal
              disabled={false}
              key={keyMaterial}
              id="combo-box-demo"
              getOptionLabel={(option) => option.name ?? ""}
              onChange={handleMaterialSelect}
              options={materials}
              value={materialSelectedFilter}
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%" }}
                  {...params}
                  label="Seleccione un material:"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("weight") ? "" : "none",
            }}
          >
            <TextField
              style={{ width: "100%" }}
              label="Buscar cantidad"
              onChange={(e) => setWeightFiltered(e.target.value)}
              value={weightFiltered}
              key={keyWeightFiltered}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("price") ? "" : "none",
            }}
          >
            <TextField
              style={{ width: "100%" }}
              label="Buscar precio"
              onChange={(e) => setPriceFiltered(e.target.value)}
              value={priceFiltered}
              key={keyPriceFiltered}
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("vehicle_name") ? "" : "none",
            }}
          >
            <Autocomplete
              onChange={handleChangeVehicle}
              options={vehicles}
              value={vehicleSelectedFilter}
              key={Math.random()}

              id="combo-box-demo"
              getOptionLabel={(option) => option.name}

              renderInput={(params) => (
                <TextField
                  style={{ width: "100%" }}
                  {...params}
                  label="Buscar vehículo"
                />
              )}
            />

          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{
              padding: "10px 10px",
              display: filtersActive.includes("trailer_name") ? "" : "none",
            }}
          >
            <Autocomplete
              onChange={handleChangeTrailer}
              options={trailers}
              value={trailerSelectedFilter}
              key={Math.random()}

              id="combo-box-trailer"
              getOptionLabel={(option) => option.name}

              renderInput={(params) => (
                <TextField
                  style={{ width: "100%" }}
                  {...params}
                  label="Buscar remolque"
                />
              )}

            />

          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
