import { Height } from "@mui/icons-material";
import { Avatar, Grid, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmailIcon from '@mui/icons-material/Email';

import React, { useEffect, useState } from "react";
import { grey } from "@mui/material/colors";



export const Profile = () => {
    const allUserInfo = {
        email: "",
        name: "",
        role: ""
    }
    const [userInfo, setUserInfo] = useState(allUserInfo)
    useEffect(() => {
        
      const token = localStorage.getItem('Token')
      const email = localStorage.getItem('email')
      const name = localStorage.getItem('name')
      const role = localStorage.getItem('role')
      setUserInfo({
        email: email,
        name: name,
        role: role
      })
    }, [])


  return (
    <>
      <div className="container-fluid  ">
        <div className="row  profileMain">
          <div className="col-4  profilColum1">
            <div className="profilColum1Content">
              <h1>Tu cuenta</h1>
              <br />
              <div>
                <div style={{ display: "flex" }}>
                <PersonIcon fontSize="large" style={{ marginRight: 10 }}/>

                 <h4>{userInfo.name}</h4>
                </div > 
              </div>
            </div>
          </div>


          <div className="col-8 profilColum2">

            <div className="profilColum2Content">
              <div>
                <div style={{ display: "flex" }}>
                    
                  <div >
                    <AssignmentIcon
                      fontSize="large"
                      style={{ color: "#6D88A6", marginRight: 10}}
                    />
                  </div>
                  <div>
                    <h4>{userInfo.role}</h4>
                  </div>
                  
                </div>
                <div style={{ display: "flex" }}>
                  <div >
                    <EmailIcon
                      fontSize="large"
                      style={{ color:  "#6D88A6", marginRight: 10 }}
                    />
                  </div>
                  <div>
                  <h4>{userInfo.email}</h4>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
