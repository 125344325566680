import React, { useState } from "react";
import { TextField, Grid, Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

export const ClientHeader = ({ setTextFilter, setCheck, check, setRefresh, handleOpen }) => {

    const handleOnChangeActive = (event, checked) => {
        setCheck(checked);
        setRefresh(Math.random())
    }

    const handleOnChangeText = (event) => {
        setTextFilter(event.target.value)
        setRefresh(Math.random())
    }
    return (
        <>

            <Grid
                container
                style={{ width: "100%", display: "flex" }}
            >
                <Grid item xs={12} sm={12} md={8} >
                    <TextField
                        style={{ width: "100%" }}
                        disabled={false}
                        label="Buscar compañia"
                        type="text"
                        placeholder=""
                        onChange={handleOnChangeText}
                        error={false}
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <Tooltip title="Filtrar por activos" placement="top" style={{ width: "100%" }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 10,
                            width: "100%"
                        }}>
                            <InputLabel>Activo</InputLabel>
                            <Checkbox checked={check} onChange={handleOnChangeActive} />
                        </div>
                    </Tooltip>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleOpen} >
                        Agregar proveedor
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

