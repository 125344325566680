import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#B6F59D'
        },
        danger: {
            main: '#F80000'
        },
        secondary: {
            main: '#F47A60'
        },
        third: {
            main: '#6D88A6'
        },
        fourth: {
            main: '#F0F0F0'
        },
        fifth: {
            main: '#F47A60'
        },
        sixth: {
            main: '#91A6FF'
        },
        error: {
            main: red.A400
        },
    }
})