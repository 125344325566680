import { Outlet, Link, NavLink, useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import { AuthService } from "app/services/AuthService";
import { RoleEnum } from "shared/enums/roleEnum";
import Logo from "logo.png";

export const NavBar = ({ onLogout, isLoggedIn }: any) => {
    const navigate = useNavigate();
    const cerrarSession = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    return (
        <>
            <nav
                className="navbar navbar-expand-lg  rounded-3 "
                style={{ backgroundColor: "#6D88A6", color: "#ffff" }}
            >
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img
                            src={Logo}
                            alt=""
                            width="40"
                            height="35"
                            style={{ borderRadius: "8px" }}
                            className="d-inline-block align-text-top"
                        />
                    </Link>

                    <div className=" navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink
                                    className={({ isActive }) =>
                                        ` nav-link ${isActive ? "active" : ""} `
                                    }
                                    to="/collections"
                                    style={{ color: "#ffff" }}
                                >
                                    Recolecciones
                                </NavLink>
                            </li>





                            <li className="nav-item">
                                <NavLink
                                    className={({ isActive }) =>
                                        ` nav-link ${isActive ? "active" : ""} `
                                    }
                                    to="/home"
                                    style={{ color: "#ffff",  display:
                                        AuthService.getCurrentRole() === RoleEnum.Usuario
                                            ? "none"
                                            : "" }}
                                >
                                    Altas
                                </NavLink>
                            </li>




                            <li className="nav-item">
                                <NavLink
                                    className={({ isActive }) =>
                                        ` nav-link ${isActive ? "active" : ""} `
                                    }
                                    to="compras"
                                    style={{ color: "#ffff",
                                        display:
                                        AuthService.getCurrentRole() === RoleEnum.Usuario
                                            ? "none"
                                            : ""
                                     }}
                                >
                                    Bitácora
                                </NavLink>
                            </li>

                            <li
                                className="nav-item"
                                style={{
                                    display:
                                        AuthService.getCurrentRole() === RoleEnum.Usuario
                                            ? "none"
                                            : "",
                                }}
                            >
                                <NavLink
                                    className={({ isActive }) =>
                                        ` nav-link ${isActive ? "active" : ""} `
                                    }
                                    to="statistics"
                                    style={{ color: "#ffff" }}
                                >
                                    Estadísticas
                                </NavLink>
                            </li>


                            <li
                                className="nav-item"
                                style={{
                                    display:
                                        AuthService.getCurrentRole() === RoleEnum.Usuario
                                            ? "none"
                                            : "",
                                }}
                            >
                                <NavLink
                                    className={({ isActive }) =>
                                        ` nav-link ${isActive ? "active" : ""} `
                                    }
                                    to="viajes"
                                    style={{ color: "#ffff" }}
                                >
                                    Viajes
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <Tooltip title="Menú" placement="top">
                        <NavDropdown title={<MenuIcon />} id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/perfil">
                                <AccountCircleIcon style={{ marginRight: 8 }} />
                                Perfil
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={cerrarSession}>
                                <LogoutIcon style={{ marginRight: 8 }} />
                                Salir
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Tooltip>
                </div>
            </nav>
        </>
    );
};
