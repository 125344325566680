import React from 'react'
import {  Buttons } from './Button'

export const Menu = () => {
  return (
    <>
    <Buttons/>
    </>
  )
}
