import React, { useState, useEffect } from "react";
import { ModalClients } from "./Modal/ModalClients";
import { ClientsTable } from "./Table/ClientsTable";
import { ClientHeader } from "./ClientHeader/ClientHeader";

import { UseFetch } from "shared/hooks/UseFetch";



const getFilters = (filter, pageSize = 10) => {
  let filtersString = `page_size=${pageSize}&active=${filter.check.toString()}`;
  if (filter.textFilter !== '') {
    filtersString += "&name=" + filter.textFilter;
  }
  return filtersString;
};

export const Clients = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [check, setCheck] = useState(true);
  const [open, setOpen] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  const [refresh, setRefresh] = useState(Infinity);


  const { getFetch } = UseFetch("clients?" + getFilters({ check, textFilter }, rowsPerPage));
  const [clientEditing, setClientEditing] = useState(null);

  const [clientsData, setClientsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);

  const onFilterChange = (event) => {
    const value = event.target.value;
    let dataFiltered = clientsData.filter((item) =>
      item.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setClientsData(dataFiltered);
    if (!value) {
      initClientData();
    }
  };

  const initClientData = () => {
    getFetch().then((response) => {
      setClientsData(response.data);
    });
  };


  const getData = (queryParams) => {
    getFetch(null, queryParams).then((response) => {
      setCount(response.data.total_items)
      setClientsData(response.data.results);
    });
  };

  useEffect(() => {
    setRefresh(Math.random());
  }, [currentPage])

  useEffect(() => {
    getData(`&page=${currentPage + 1}`)
  }, [refresh, rowsPerPage])

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <h1>Proveedores</h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ClientHeader
          setTextFilter={setTextFilter} check={check} setRefresh={setRefresh}
          setCheck={setCheck} handleOpen={handleOpen} />

        <ModalClients
          setClientsData={setClientsData}
          setRefreshList={setRefresh}
          setCurrentPage={setCurrentPage}
          clientEditing={clientEditing}
          setClientEditing={setClientEditing}
          setOpen={setOpen}
          open={open}
        />
      </div>
      <hr />
      <ClientsTable
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        data={clientsData}
        count={count}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setClientEditing={setClientEditing}
      />
    </>
  );
};
