import { Column } from 'shared/components/Table/Table.type';

export const columnsTravels: Column[] = [
    {
        id: "start_time_zone",
        label: "Fecha inicio",
        minWidth: 170,
        align: "center",
        render: (e: any) => (
            <><span>{formatDate(e.start_time_zone)}</span></>
        )
    },
    {
        id: "start_time_zone",
        label: "Fecha fin",
        minWidth: 170,
        align: "center",
        render: (e: any) => (
            <><span>{formatDate(e.end_time_zone)}</span></>
        )
    },
    {
        id: "client_short_name",
        label: "Proveedor",
        minWidth: 170,
        align: "center",
    },
    {
        id: "driver_name",
        label: "Chofer",
        minWidth: 170,
        align: "center",
    },
    {
        id: "time",
        label: "Tiempo",
        minWidth: 170,
        align: "center",
        render: (e: any) => (
            <><span>{diffDate(e.start_time_zone, e.end_time_zone)}</span></>
        )
    },
    {
        id: "vehicle_name",
        label: "Vehiculo",
        minWidth: 170,
        align: "center",
    },
    {
        id: "trailer_name",
        label: "Remolque",
        minWidth: 170,
        align: "center",
    },
    {
        id: "edit",
        label: "Modificar",
        minWidth: 100,
        align: "center",
    },
    {
        id: "delete",
        label: "Eliminar",
        minWidth: 100,
        align: "center",
        
    },



];

const formatDate = (fechaOriginal: string) => {
    const fecha = new Date(fechaOriginal);
    const opciones: any = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
    const fechaFormateada = fecha.toLocaleDateString('es-ES', opciones).replace(/,/, '')
    return fechaFormateada;
}

const diffDate = (fecha1Str: string, fecha2Str: string) => {

    // Convertir las cadenas a objetos Date
    const fecha1 = new Date(fecha1Str);
    const fecha2 = new Date(fecha2Str);

    // Verificar que las fechas son válidas
    if (isNaN(fecha1.getTime()) || isNaN(fecha2.getTime())) {
        return `0`;
    } else {
        // Calcular la diferencia en milisegundos
        const diferenciaMs = fecha2.getTime() - fecha1.getTime();

        // Convertir milisegundos a horas y minutos
        const diferenciaMinutos = Math.floor(diferenciaMs / 1000 / 60);
        const hours = Math.floor(diferenciaMinutos / 60);
        const minutes = diferenciaMinutos % 60;

        return `${hours} horas ${minutes} minutos`;
    }
}