import { Column } from 'shared/components/Table/Table.type';

export const columns: Column[] = [
    {
        id: "username",
        label: "Usuario",
        minWidth: 170,
        align: "center",
    },
    {
        id: "first_name",
        label: "Nombre",
        minWidth: 170,
        align: "center",
    },
    {
        id: "last_name",
        label: "Apellido",
        minWidth: 170,
        align: "center",
    },
    {
        id: "email",
        label: "Email",
        minWidth: 170,
        align: "center",
    },

    {
        id: "role_description",
        label: "Rol",
        minWidth: 170,
        align: "center",
   
    },
    {
        id: "",
        label: "Modificar",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "",
        label: "Eliminar",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
    },
];