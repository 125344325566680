import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Fade,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { useForm } from "shared/hooks/useForm";
import { ModalLayout } from "shared/components/ModalLayout";
import { TodoList } from "./TodoList";
import { TodoForm } from "./TodoForm";
import { UseFetch } from "shared/hooks/UseFetch";
import { TabPanel } from "shared/components/TabPanel";
import { AlertDialog } from "shared/components/AlertDialog";

const formValidation = {
  company_name: [(value) => value.length >= 6, "El correo debe de tener un @."],
  short_name: [(value) => value.length >= 6, "Minimo 6 caracteres."],
};

const formData = {
  company_name: "",
  short_name: "",
  collection_time_limit: "",
  active: true,
  materials: [],
};

export const ModalClients = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [formSubmitted, setFormSubmitted] = useState([]);
  const [todoItems, setTodoItems] = useState([]);
  const [tab, setTab] = useState(0);
  const [todoItemsSaved, setTodoItemsSaved] = useState([]);
  const [units, setUnits] = useState([]);
  const [materialsPref, setMaterialsPref] = useState([]);
  
  const {
    company_name,
    short_name,
    collection_time_limit,
    active,
    onInputChange,
    formState,
    onResetForm,
    setFormState,
    onCheckChange,
  } = useForm(formData, formValidation);

  const fetchAddClient = UseFetch("clients/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...formState, collection_time_limit: convertTimeToMinutes(collection_time_limit) }),
  });

  const fetchUpdateClient = UseFetch(`clients/${props.clientEditing}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...formState, collection_time_limit: convertTimeToMinutes(collection_time_limit) }),
  });

  const fetchListClients = UseFetch("clients/");

  const fetchMaterials = UseFetch("materials/?page_size=0");
  const fetchUnitsOfMeasure = UseFetch("materials/units-of-measure?page_size=0");

  const initFetchUnitsOfMeasure = () => {
    fetchUnitsOfMeasure.getFetch().then((response) => {
      setUnits(response.data);
    });
  };

  const initFetchMaterials = () => {
    fetchMaterials.getFetch().then((response) => {
      setMaterialsPref(response.data);
    });
  };

  const handleRemoveTodo = (id) => {
    setTodoItems((prev) => prev.filter((t) => t.id !== id));
  };

  const handleClose = () => {
    closeView();
    props.setOpen(false);
    setTab(0);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    initFetchUnitsOfMeasure();
    initFetchMaterials();
    if (props.clientEditing) {
      searchClientById(props.clientEditing);
      props.setOpen(true);
    }
  }, [props.clientEditing]);

  const fetchClientById = UseFetch("clients/" + props.clientEditing);
  const searchClientById = (id) => {
    fetchClientById.getFetch().then((response) => {
      if (response.data) {
        const { data } = response;
        let materialsFormatted = data.materials.map((material) => {
          material.material_id = material.material.id;
          material.unit_of_measure = material.unit_of_measure?.id;
          return material;
        });
        setTodoItems(materialsFormatted);
        setTodoItemsSaved(materialsFormatted);
        setFormState({
          ...formState,
          ["short_name"]: data.short_name,
          ["company_name"]: data.company_name,
          ["active"]: data.active,
          ["collection_time_limit"]: convertMinutesToTime(data.collection_time_limit),
        });
      }
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    formState.materials = [...todoItems];
    const updatedFormState = {
      ...formState,
      collection_time_limit: convertTimeToMinutes(collection_time_limit),
    };
    
    if (props.clientEditing) {
      let request = updatedFormState;
      request.materials = request.materials.map((element) => {
        let material = { ...element };
        if (!todoItemsSaved.includes(element)) {
          delete material.id;
          return material;
        } else {
          return material;
        }
      });
      fetchUpdateClient.getFetch(request).then(
        (response) => {
          if (response.data.type === 'error') {
            setTypeAlert(response.data.type);
            setContent(response.data.message);
          } else {
            setTypeAlert("success");
            setContent(`Se ha editado con éxito.`);
          }
          setOpenDialog(true);
          closeView();
        },
        (error) => {
          setTypeAlert("error");
          setContent(`Se ha producido un error al editar.`);
          setOpenDialog(true);
          closeView();
        }
      );
    } else {
      await fetchAddClient.getFetch(updatedFormState).then(
        (response) => {
          if (response.data.type === 'error') {
            setTypeAlert("error");
            setContent(response.data.message);
          } else {
            setTypeAlert("success");
            setContent(`Se ha guardado con éxito.`);
          }
          setOpenDialog(true);
          closeView();
        },
        (error) => {
          setTypeAlert("error");
          setContent(`Se ha producido un error al guardar.`);
          setOpenDialog(true);
          closeView();
        }
      );
    }
  };

  const isDisable = () => {
    if (
      formState.company_name !== "" &&
      formState.short_name !== "" &&
      todoItems.length > 0
    ) {
      return false;
    }
    return true;
  };

  const closeView = () => {
    onResetForm();
    setTodoItems([]);
    props.setOpen(false);
    props.setRefreshList(Math.random());
    props.setClientEditing(null);
    props.setOpen(false);
    setTab(0);
    setTodoItemsSaved([]);
  };

  const handellTodoFormSubmit = (values) => {
    setTodoItems(values);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const getTitle = () =>
    props.clientEditing
      ? `Editando proveedor: ${short_name.toUpperCase()}`
      : "Alta de proveedor";

  return (
    <>
      <AlertDialog
        type={typeAlert}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <Modal open={props.open} onClose={handleClose} closeAfterTransition>
        <Fade in={props.open}>
          <div className="modal-container">
            <ModalLayout
              title={getTitle()}
              width={"60%"}
              handleClose={handleClose}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Proveedor" />
                  <Tab label="Materiales" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={2} sx={{ minHeight: 100 }}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <TextField
                        disabled={false}
                        label="Nombre de la compañia"
                        type="text"
                        placeholder=""
                        fullWidth
                        name="company_name"
                        value={company_name}
                        onChange={onInputChange}
                        error={false}
                        helperText={false}
                        inputProps={{ maxLength: 250 }}
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <TextField
                        disabled={false}
                        label="Nombre corto"
                        type="text"
                        placeholder="redesa"
                        fullWidth
                        name="short_name"
                        value={short_name}
                        onChange={onInputChange}
                        error={false}
                        helperText={false}
                        inputProps={{ maxLength: 60 }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <TextField
                        disabled={false}
                        label="Tiempo"
                        type="time"
                        placeholder=""

                        name="collection_time_limit"
                        value={collection_time_limit}
                        onChange={onInputChange}
                        error={false}
                        helperText={false}
                        inputProps={{ maxLength: 250 }}
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Activo"
                        onChange={onCheckChange}
                        checked={active}
                        name="active"
                      />
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Grid item xs={12} sm={12}>
                  <TodoForm
                    initMaterials={todoItemsSaved}
                    onSubmit={handellTodoFormSubmit}
                    materials={todoItems}
                    setMaterials={setTodoItems}
                    materialsPref={materialsPref}
                    units={units}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TodoList
                    todoItems={todoItems}
                    onRemove={handleRemoveTodo}
                    setMaterials={setTodoItems}
                    materialsPref={materialsPref}
                    units={units}
                  />
                </Grid>
              </TabPanel>
              <Grid
                container
                spacing={2}
                xs={12}
                sx={{ mb: 2, mt: 1 }}
                style={{ bottom: "5%" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: "#afafaf", color: "white" }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={isDisable()}
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </ModalLayout>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const convertTimeToMinutes = (time) => {
  if (!time) return 0;
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

const convertMinutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
};
