import { useEffect, useState } from 'react';
import './Collections.css';
import { ModalAdd } from './ModalAdd/ModalAdd';
import { UseFetch } from 'shared/hooks/UseFetch';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import TimeBasedButton from './Helpers/TimeButton';
import { useNavigate } from 'react-router-dom';
import { TablePagination, Grid, Button, } from '@mui/material';
import { Card, CardContent, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
export const Collections = () => {
    const navigate = useNavigate();

    const [dataCollectionsPending, setDataCollectionsPending] = useState([] as any[]);
    const [dataCollectionsInitialized, setDataCollectionsInitialized] = useState([] as any[]);

    const [pageCollectionPending, setPageCollectionPending] = useState(0);
    const [pageCollectionInitialized, setPageCollectionInitialized] = useState(0);

    const [pageSizeCollectionPending, setPageSizeCollectionPending] = useState(10);
    const [pageSizeCollectionInitialized, setPageSizeCollectionInitialized] = useState(10);

    const [countCollectionPending, setCountCollectionPending] = useState(0);
    const [countCollectionInitialized, setCountCollectionInitialized] = useState(0);
    const [idEdit, setIdEdit] = useState(0);

    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(Infinity);
    const [clients, setClients] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [trailers, setTrailers] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const fetchListClients = UseFetch("clients/?page_size=0");
    const fetchListDrivers = UseFetch("drivers/?page_size=0&active=true");
    const fetchListTrailers = UseFetch("trailers/?page_size=0");
    const fetchVehicles = UseFetch("vehicles/?page_size=0");

    const fetchCollectionsInitialized = UseFetch("collections/?page_size=" + pageSizeCollectionInitialized);
    const fetchCollectionsPending = UseFetch("collections/?page_size=" + pageSizeCollectionPending);
    const fetchUpdate = UseFetch('collections/', {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
    });

    useEffect(() => {
        getDataCollectionsPending(`&page=${pageCollectionPending + 1}`)
    }, [pageCollectionPending, open, refresh])

    useEffect(() => {
        getDataCollectionsInitialized(`&page=${pageCollectionInitialized + 1}`)
    }, [pageCollectionInitialized, open, refresh])

    useEffect(() => {
        const load = async () => {
            initClients();
            initDrivers();
            initTrailers();
            initVehicles();
        };
        load();
    }, []);

    const formatDateShow = (fechaOriginal: string) => {
        if (!fechaOriginal) {
            return ''

        }
        const result = `${fechaOriginal.substring(8, 10)}/${fechaOriginal.substring(5, 7)}/${fechaOriginal.substring(0, 4)}`
        return result
    };


    const getDataCollectionsPending = (params: string) => {
        fetchCollectionsPending.getFetch(null, params + '&status=1').then((response: any) => {
            setCountCollectionPending(response.data.total_items)
            setDataCollectionsPending(response.data.results);
        });
    }

    const getDataCollectionsInitialized = (params: string) => {
        fetchCollectionsInitialized.getFetch(null, params + '&status=2,3').then((response: any) => {
            setCountCollectionInitialized(response.data.total_items)
            setDataCollectionsInitialized(response.data.results);
        });
    }

    const handleChangePageSizeCollectionPending = (event: any) => {
        setPageSizeCollectionPending(event.target.value);
        setPageCollectionPending(0);
        setRefresh(Math.random())
    };

    const handleChangePageSizeCollectionInitialized = (event: any) => {
        setPageSizeCollectionInitialized(event.target.value);
        setPageCollectionInitialized(0);
        setRefresh(Math.random())
    };

    const initVehicles = () => {
        fetchVehicles.getFetch().then((response) => {
            setVehicles(response.data);
        });
    };

    const initClients = () => {
        return new Promise((resolve) => {
            fetchListClients.getFetch().then((response: any) => {
                setClients(response.data);
                resolve(true);
            });
        });
    };

    const initDrivers = () => {
        return new Promise((resolve) => {
            fetchListDrivers.getFetch().then((response: any) => {
                setDrivers(response.data);
                resolve(true);
            });
        });
    };

    const initTrailers = () => {
        return new Promise((resolve) => {
            fetchListTrailers.getFetch().then((response: any) => {
                setTrailers(response.data);
                resolve(true);
            });
        });
    };

    const RetorCollection = (collection: any) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: `La recolección para hacia el proveedor ${collection.client_short_name} con el chofer ${collection.driver_name} se reestablecera a no iniciado`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Restablecer",
            confirmButtonColor: "#b6f59d",
            cancelButtonText: "Cancelar",
            html: (
                <>
                    <h4>Confirme para reestablecer a viajes pendientes.</h4>

                </>
            ),

        }).then((result) => {
            if (result.isConfirmed) {

                collection.status = 1;
                let dataClone = [...dataCollectionsPending];
                const index = dataClone.findIndex(item => item.idProveedor === collection.idProveedor);
                if (index !== -1) {
                    dataClone[index] = { ...dataClone[index], ...collection };
                }
                setDataCollectionsPending(dataClone);
                updateCollection(collection.id, collection.status, collection.startTime);
            }
        });
    }

    const InitializeCollection = (collection: any) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: `La recolección para hacia el proveedor ${collection.client_short_name} con el chofer ${collection.driver_name} se inicializará`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Iniciar",
            confirmButtonColor: "#b6f59d",
            cancelButtonText: "Cancelar",
            html: (
                <>
                    <h4>Confirme en los siguientes cuadros de texto la fecha y hora de salida:</h4>
                    <div>
                        <div>
                            <label htmlFor="input1">Fecha:</label>
                            <input
                                type="date"
                                id="input1"
                                className="swal2-input"
                            />
                        </div>
                        <div>
                            <label htmlFor="input2">Hora:</label>
                            <input
                                type="time"
                                id="input2"
                                className="swal2-input"
                            />
                        </div>
                    </div>
                </>
            ),
            didOpen: () => {
                const now = new Date();
                const dateInput = document.getElementById('input1') as HTMLInputElement;
                const timeInput = document.getElementById('input2') as HTMLInputElement;

                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0');
                const day = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');

                dateInput.value = `${year}-${month}-${day}`;
                timeInput.value = `${hours}:${minutes}`;
            },
            preConfirm: () => {
                const input1 = (document.getElementById('input1') as HTMLInputElement).value;
                const input2 = (document.getElementById('input2') as HTMLInputElement).value;
                if (!input1 || !input2) {
                    Swal.showValidationMessage('Both fields are required');
                }
                return { fecha: input1, hora: input2 };
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const { fecha, hora } = result.value!;

                collection.startTime = `${fecha}T${hora}`;
                collection.status = 2;
                let dataClone = [...dataCollectionsPending];
                const index = dataClone.findIndex(item => item.idProveedor === collection.idProveedor);
                if (index !== -1) {
                    dataClone[index] = { ...dataClone[index], ...collection };
                }
                setDataCollectionsPending(dataClone);
                updateCollection(collection.id, collection.status, collection.startTime);
            }
        });
    }

    const EditCollection = (collection: any) => {
        setIdEdit(collection.id);
        handleOpen();
    }


    const stop = (collection: any) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: `La recolección para hacia el proveedor ${collection.client_short_name} con el chofer ${collection.driver_name} se finalizará`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Finalizar",
            confirmButtonColor: "#b6f59d",
            cancelButtonText: "Cancelar",
            html: (
                <>
                    <h4>Confirme en los siguientes cuadros de texto la fecha y hora de salida:</h4>
                    <div>
                        <div>
                            <label htmlFor="input1">Fecha:</label>
                            <input
                                type="date"
                                id="input1"
                                className="swal2-input"
                            />
                        </div>
                        <div>
                            <label htmlFor="input2">Hora:</label>
                            <input
                                type="time"
                                id="input2"
                                className="swal2-input"
                            />
                        </div>
                    </div>
                </>
            ),
            didOpen: () => {
                const now = new Date();
                const dateInput = document.getElementById('input1') as HTMLInputElement;
                const timeInput = document.getElementById('input2') as HTMLInputElement;

                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0');
                const day = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');

                dateInput.value = `${year}-${month}-${day}`;
                timeInput.value = `${hours}:${minutes}`;
            },
            preConfirm: () => {
                const input1 = (document.getElementById('input1') as HTMLInputElement).value;
                const input2 = (document.getElementById('input2') as HTMLInputElement).value;
                if (!input1 || !input2) {
                    Swal.showValidationMessage('Both fields are required');
                }
                return { fecha: input1, hora: input2 };
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const { fecha, hora } = result.value!;

                collection.end_time = `${fecha}T${hora}`;
                collection.status = 3;
                let dataClone = [...dataCollectionsPending];
                const index = dataClone.findIndex(item => item.idProveedor === collection.idProveedor);
                if (index !== -1) {
                    dataClone[index] = { ...dataClone[index], ...collection };
                }
                setDataCollectionsPending(dataClone);
                updateCollection(collection.id, collection.status, collection.end_time);
            }
        })
    }

    const register = (collection: any) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: `¿Desea registrar la recolección?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Registrar",
            confirmButtonColor: "#b6f59d",
            cancelButtonText: "Cancelar",
            html: (
                <>
                    <h4>Datos:</h4>
                    <div>
                        <div>
                            <p>
                                Proveedor {collection.client_short_name}
                            </p>
                            <p>
                                Chofer {collection.driver_name}
                            </p>
                        </div>
                    </div>
                </>
            )
        }).then((result) => {
            if (result.isConfirmed) {
                const { fecha, hora } = result.value!;
                collection.end_time = `${fecha}T${hora}`;
                collection.status = 4;
                let dataClone = [...dataCollectionsPending];
                const index = dataClone.findIndex(item => item.idProveedor === collection.idProveedor);
                if (index !== -1) {
                    dataClone[index] = { ...dataClone[index], ...collection };
                }
                setDataCollectionsPending(dataClone);
                // updateCollection(collection.id, collection.status, collection.end_time);
                navigate(`/compras/${true}/${collection.id}/${collection.client.id}/${collection.driver.id}/${collection.vehicle.id}/${collection.trailer.id}`);
            }
        })
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangePageCollectionPending = (event: any, newPage: number) => {
        setPageCollectionPending(newPage);
    };

    const handleChangePageCollectionInitialized = (event: any, newPage: number) => {
        setPageCollectionInitialized(newPage);
    };

    const formatDate = (fechaOriginal: string) => {
        const fecha = new Date(fechaOriginal);
        const opciones: any = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
        const fechaFormateada = fecha.toLocaleDateString('es-ES', opciones).replace(/,/, '')
        return fechaFormateada;
    }

    const updateCollection = (id: any, status: number, time: string) => {
        let obj = null;
        if (status === 2) {
            obj = {
                status: status,
                start_time: time
            }
        }
        else if (status === 3) {
            obj = {
                status: status,
                end_time: time
            }
        } else {
            obj = {
                status: status
            }
        }

        if (obj != null) {
            fetchUpdate.getFetch(obj, `${id}/`).then(() => {
                setRefresh(Math.random());
            });
        }
    }
    return (<>
        <ModalAdd
            setOpen={setOpen}
            setRefreshList={setRefresh}
            refresh={refresh}
            clients={clients}
            trailers={trailers}
            drivers={drivers}
            vehicles={vehicles}
            open={open}
            idEdit={idEdit}
            setIdEdit={setIdEdit}
        />
        <h1>Recolecciones
            <Button variant="contained" className="btn mb-2" style={{ marginLeft: '20px' }} onClick={() => window.location.reload()} >
                <AutorenewIcon></AutorenewIcon> Refrescar pagina
            </Button>
        </h1>
        <div>

            <div className="row" >
                <div className="col-4">
                    <Button variant="contained" className="btn mb-2" style={{ 'backgroundColor': 'rgb(109 136 166)', color: 'white', width: '100%' }} onClick={() => handleOpen()} >
                        Programar recoleccion
                    </Button>

                </div>
            </div>
            <div className="row" style={{ padding: ' 10px' }}>
                <div className="col-4" style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '80vh'
                }}>
                    <div>
                        <div className="row border-top border-bottom" style={{ paddingLeft: '12px' }}>
                            <Grid item xs={12}>
                                <h4 style={{ padding: '12px' }}>
                                    Recolecciones pendientes
                                </h4>
                            </Grid>
                        </div>
                        <table className="table table-striped table-bordered table-responsive" >
                            <tbody>
                                {dataCollectionsPending.map((collection: any, index: number) => (
                                    <tr key={index}>
                                        <td className="text-center">
                                            <span >Destino:</span> <br />
                                            <span> {collection.client_short_name}</span> <br />
                                        </td>

                                        <td className="text-center">
                                            <span>Chofer:</span> <br />
                                            <span> <b>{collection.driver_name}</b> </span>
                                        </td>

                                        <td className="text-center">
                                            <span>Vehículo:</span> <br />
                                            <span> <b>{collection.vehicle_name}</b> </span>
                                        </td>

                                        <td className="text-center">
                                            <span>Fecha:</span><br />
                                            <span> {formatDateShow(collection.scheduled_date)}</span>
                                        </td>

                                        <td className="text-center">
                                            <Button
                                                variant="contained"
                                                onClick={() => InitializeCollection(collection)}
                                                style={{
                                                    fontSize: '0.875rem',  // Tamaño de la fuente reducido
                                                    padding: '6px 12px',   // Reducir el tamaño del botón
                                                    margin: '5px'     // Espacio entre botones
                                                }}>
                                                Iniciar
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    backgroundColor: 'rgb(109 136 166)', 
                                                    color: 'white',
                                                    fontSize: '0.875rem',  
                                                    padding: '6px 12px',   

                                                }}
                                                onClick={() => EditCollection(collection)}>
                                                Editar
                                            </Button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {
                        <div style={{
                            bottom: '20px',
                            padding: '10px',
                            textAlign: 'right',
                            left: 0,

                        }}>
                            {
                                countCollectionPending > 0 ? <div >
                                    <TablePagination
                                        labelRowsPerPage='Registros por página'
                                        rowsPerPageOptions={[10, 15, 20]}
                                        component="div"
                                        count={countCollectionPending}
                                        rowsPerPage={pageSizeCollectionPending}
                                        page={pageCollectionPending}
                                        onPageChange={handleChangePageCollectionPending}
                                        onRowsPerPageChange={handleChangePageSizeCollectionPending} 
                                    />
                                </div> : ""
                            }
                        </div>
                    }
                </div>
                <div className="col-8" style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '110vh',
                    alignItems: 'center',
                }}>

                    <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="row border-top " style={{ paddingLeft: '12px' }}>
                            <Grid item xs={12}>
                                <h4 style={{ padding: '12px' }}>
                                    Recolecciones en curso
                                </h4>
                            </Grid>
                        </div>
                        {
                            dataCollectionsInitialized.map((e) => (
                                <Card key={e.id} variant="outlined" >
                                    <CardContent>
                                        {
                                            e.status === 3 &&
                                            <Typography variant="h6" component="h4" style={{ display: 'flex', justifyContent: 'space-between', color: 'red' }}>
                                                Viaje Finalizado, favor de registrarlo
                                            </Typography>
                                        }
                                        <Typography variant="h6" component="h4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <strong>Cliente: {e.client_short_name}    </strong><strong> Hora Salida: {formatDate(e.start_time_zone)}</strong>
                                        </Typography>
                                        <Typography color="textSecondary" className='pt-2'>
                                            <strong>Chofer:</strong> {e.driver_name}
                                            {e.vehicle_name &&
                                                <>
                                                    <strong style={{ paddingLeft: '5px' }}>Vehiculo:</strong> {e.vehicle_name}
                                                </>
                                            }
                                            {e.trailer_name &&
                                                <>
                                                    <strong style={{ paddingLeft: '5px' }}>Remolque:</strong> {e.trailer_name}
                                                </>
                                            }
                                        </Typography>


                                        <Typography variant="body2" component="p" className='pt-2'>
                                            <div className="row">
                                                <div className="col-5">
                                                    <TimeBasedButton timeLimit={e.client.collection_time_limit} fechafin={e.start_time_zone} onClickMethod={stop} model={e} secondParamClick={false} label="Fin" status={e.status} />
                                                </div>
                                                <div className="col-5">
                                                    <Button variant="contained" disabled={e.status === 2} className="btn w-100" onClick={() => register(e)} style={{ 'backgroundColor': e.status === 2 ? 'gray' : 'rgb(109 136 166)', color: 'white' }}>
                                                        Registrar
                                                    </Button>
                                                </div>
                                                <div className="col-2">
                                                    <Button
                                                        variant="contained"
                                                        className="btn w-100"
                                                        onClick={() => RetorCollection(e)}
                                                        style={{
                                                            backgroundColor: 'rgb(109 136 166)', 
                                                            color: 'white',
                                                        }}
                                                        endIcon={<AutorenewIcon />} // Ícono al final del texto
                                                    >

                                                    </Button>
                                                </div>
                                            </div>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))
                        }
                    </div>
                    <div style={{
                        bottom: '20px',
                        padding: '10px',
                        textAlign: 'right',
                        right: 0
                    }}>
                        {
                            countCollectionInitialized > 0 ? <div>
                                <TablePagination
                                    labelRowsPerPage='Registros por página'
                                    rowsPerPageOptions={[10, 15, 20]}
                                    component="div"
                                    count={countCollectionInitialized}
                                    rowsPerPage={pageSizeCollectionInitialized}
                                    page={pageCollectionInitialized}
                                    onPageChange={handleChangePageCollectionInitialized}
                                    onRowsPerPageChange={handleChangePageSizeCollectionInitialized} // This will update the rowsPerPage state
                                />
                            </div> : ""
                        }
                    </div>
                </div >
            </div >
        </div >


    </>)
};