import React, { useState, useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export const Chart = ({ data, mostrarTotal }) => {
  const [chart, setChart] = useState(null);
  const [Statisques, setStatisques] = useState([
    { category: "Category ", value: 20 },
  ]);
  // TODO: Improve the method to set the api url

  useEffect(() => {
    setStatisques(
      data.map((obj) => {
        return { category: obj.material, value: obj.total };
      })
    );
    ;
  }, [data]);

  useEffect(() => {
    const data = Statisques;
    const newChart = am4core.create("chartdiv", am4charts.PieChart);
    newChart.data = data;

    const series = newChart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "category";

    setChart(newChart);

    return () => {
      newChart.dispose();
    };
  }, [Statisques]);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
};
