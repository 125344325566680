import { createBrowserRouter, redirect } from "react-router-dom";
import { ErrorPage } from "../app/components/ErrorPage";
import { Clients } from "../app/pages/client/Clients";
import { MainApp } from "../app/pages/home/Home";
import { HomePage } from "../app/pages/home/HomePage";
import { Materials } from "../app/pages/materials/Materials";
import { Purchase } from "../app/pages/purchase/Purchase";
import { Statistics } from "../app/pages/statistics/statistics";
import { Profile } from "../app/pages/profile/Profile";
import { User } from "../app/pages/users/User";
import { Vehicles } from "../app/pages/vehicles/Vehicles";
import { LogIn } from "../auth/pages/LogIn";
import { Driver } from "app/pages/drivers/Driver";
import { Trailer } from "app/pages/trailers/Trailer";
import { Collections } from "app/pages/collections/Collections";
import { Travels } from "app/pages/travels/Travels";
export const authStatus = 'not-authentication';



const getAuthentication = () => {
    let auth = localStorage.getItem('Token') ? 'authentication' : 'not-authentication'
        ;
    return auth;
}

export const GetRoutes = () => createBrowserRouter(
    (getAuthentication() === 'authentication'
    )
        ? [

            {
                path: "",
                element: <MainApp />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: "",
                        element: <Collections />,
                    },
                ]
            },
            {
                path: "/",
                element: <MainApp />,
                errorElement: <ErrorPage />,
                children: [

                    {
                        path: "home",
                        element: <HomePage />,
                    },
                    {
                        path: "compras",
                        element: <Purchase />,
                    },
                    {
                        path: "compras/:openModal/:id/:idProveedor/:idDriver/:idVehicle/:idTrailer",
                        element: <Purchase />,
                    },
                    {
                        path: "compras/:openModal/:id/:idProveedor/:idDriver",
                        element: <Purchase />,
                    },
                    {
                        path: "clientes",
                        element: <Clients />,
                    },
                    {
                        path: "statistics",
                        element: <Statistics />,
                    },
                    {
                        path: "perfil",
                        element: <Profile />,
                    },
                    {
                        path: "materiales",
                        element: <Materials />,
                    },
                    {
                        path: "vehiculos",
                        element: <Vehicles />,
                    },
                    {
                        path: "usuarios",
                        element: <User />,
                    },
                    {
                        path: "choferes",
                        element: <Driver />,
                    },
                    {
                        path: "remolques",
                        element: <Trailer />,
                    },
                    {
                        path: "collections",
                        element: <Collections />,
                    },
                    {
                        path: "viajes",
                        element: <Travels />,
                    },
                ]
            }
        ]
        :
        [

            {
                path: "/",
                element: <LogIn />,
                errorElement: <ErrorPage />,
                children: [


                    {
                        path: "login",
                        element: <LogIn />,
                    },
                    {
                        path: "/*",
                        element: <LogIn />,
                    },


                ]
            }
        ]



);