import React from 'react'
import { Outlet } from 'react-router-dom'
import { UserProvider } from '../../../util/provider/UserProvider'
import Header from './components/Header'

export const MainApp = () => {
    return (
        <UserProvider>
            <Header />
            <hr />
            <Outlet />
        </UserProvider>
    )
}
