import React, { useEffect, useState } from "react";
import PurchasesContent from "./PurchasesContent";
import { Button } from "@mui/material";
import moment from "moment";
import { DeleteForever, Edit } from "@mui/icons-material";
import { RoleEnum } from "../../../../shared/enums/roleEnum";
import { UseFetch } from "../../../../shared/hooks/UseFetch";
import { AuthService } from "../../../services/AuthService";

const formatDate = (currentDate) => {
  if (currentDate == "") {
    return "";
  }

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
const getFilters = (filter, currentPage = 0, pageSize = 10) => {

  let filterString = `page=${currentPage + 1}&page_size=${pageSize}`;
  if (filter.client_id && filter.client_id != -1) {
    filterString += "&client_id=" + filter.client_id;
  }

  if (
    filter?.startDate &&
    filter?.startDate != "" &&
    filter?.endDate &&
    filter?.endDate != ""
  ) {
    filterString +=
      "&start_date=" +
      formatDate(filter?.startDate) +
      "&end_date=" +
      formatDate(filter?.endDate);
  }
  if (filter.material_id && filter.material_id != -1) {
    filterString += "&material_id=" + filter.material_id;
  }

  if (filter.vehicle_id && filter.vehicle_id != -1) {
    filterString += "&vehicle_id=" + filter.vehicle_id;
  }

  if (filter.trailer_id && filter.trailer_id != -1) {
    filterString += "&trailer_id=" + filter.trailer_id;
  }

  if (filter.price && filter.price != -1) {
    filterString += "&price=" + filter.price;
  }

  if (filter.ticket) {
    filterString += "&ticket=" + filter.ticket;
  }

  if (filter.control_number) {
    filterString += "&control_number=" + filter.control_number;
  }

  if (filter.weight) {
    filterString += "&weight=" + filter.weight;
  }

  if (filter.outbound_reference_number) {
    filterString += "&outbound_reference_number=" + filter.outbound_reference_number;
  }


  return filterString;
};

export const PurchaseTable = ({
  refreshList,
  filter,
  editMethod,
  deleteMethod,
  setData,
  data,
  setLoading,
  currentPage,
  setCount,
  setFiltersActive,
  filtersActive,
  count,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  options
}) => {
  const setInitColumns = () => {
    let newColumns = [
      { id: "client_short_name", label: "Proveedor", minWidth: 170 },
      {
        id: "formated_date",
        label: "Fecha",
        minWidth: 170,
        align: "right",
        format: (value) => moment(value).format("YYYY-MM-DD"),
      },
      { id: "outbound_reference_number", label: "Folio salida", minWidth: 50 },
      { id: "ticket", label: "Folio ticket", minWidth: 50 },
      { id: "control_number", label: "Número control", minWidth: 50 },
      { id: "material_name", label: "Material", minWidth: 100 },
      {
        id: "weight",
        label: "Cantidad",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "price",
        label: "Precio",
        minWidth: 170,
        align: "right",
        format: (value) =>
          value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
      },
      {
        id: "vehicle_name",
        label: "Vehículo",
        minWidth: 170,
        align: "right",
        format: (value) => value,
      },
      {
        id: "trailer_name",
        label: "Remolque",
        minWidth: 170,
        align: "right",
        format: (value) => value,
      },
      {
        id: "notes",
        label: "Notas",
        minWidth: 170,
      },
      {
        id: "edit",
        label: "Modificar",
        minWidth: 100,
        align: "right",
        render: (editMethod, row) => (
          <Button
            variant="contained"
            style={{ backgroundColor: "#6D88A6", color: "white" }}
            onClick={() => editMethod(row)}
          >
            <Edit />
          </Button>
        ),
      },
    ];

    AuthService.getCurrentRole() !== RoleEnum.Usuario &&
      newColumns.push({
        id: "delete",
        label: "Eliminar",
        minWidth: 100,
        align: "right",
        render: (deleteMethod, id) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => deleteMethod(id)}
          >
            <DeleteForever style={{ color: "white" }} />
          </Button>
        ),
      });

    return newColumns;
  }

  const [pageSize, setPageSize] = useState(10)
  const fetchPurchaseFiltered = UseFetch(`purchases?${getFilters(filter, currentPage, rowsPerPage)}`);
  const [columns, setColumns] = useState(setInitColumns())


  useEffect(() => {
    requestData();
  }, [currentPage, pageSize, filter, refreshList, rowsPerPage])

  const requestData = async (queryParams) => {
    let loadData;
    setLoading(true);
    if (
      filter.clientId !== -1 ||
      (filter.startDate !== "" && filter.endDate !== "")
    ) {
      loadData = async () => {
        const response = await fetchPurchaseFiltered.getFetch(null, queryParams);
        setCount(response.data.total_items);
        setData(response.data.results);

        setLoading(false);
      };
    } else {
      loadData = async () => {
        const response = await fetchPurchaseFiltered.getFetch(null, queryParams);
        setCount(response.data.total_items);
        setData(response.data.results);
        setLoading(false);
      };
    }
    loadData();
  };

  useEffect(() => {
    const standarsColumns = setInitColumns();
    const newColumns = standarsColumns.filter(column => options.find(option => column.id == option.value));

    setColumns([...newColumns]);
  }, [options])


  if (!data) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <PurchasesContent
        filtersActive={filtersActive}
        columns={columns}
        rows={data}
        count={count}
        editMethod={editMethod}
        deleteMethod={deleteMethod}
        setFiltersActive={setFiltersActive}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
