import { TextField, Grid, Select, MenuItem } from "@mui/material";
import React, { useState } from "react";

const Filters = ({ setTextFilter, setTimeFilter, setRefresh, setTimeInHoursFilter, tabActive }) => {
  const handleOnChangeText = (event) => {
    setTextFilter(event.target.value);
    setRefresh(Math.random());
  };

  const handleOnChangeTextTime = (hours, minutes) => {
    const totalMinutes = convertTimeToMinutes(hours, minutes);
    setTimeInHoursFilter(totalMinutes);
    setRefresh(Math.random());
  };

  const [selectedOption, setSelectedOption] = useState('asc');
  const handleChange = (event) => {

    setSelectedOption(event.target.value);
    setTimeFilter(event.target.value);
  };

  const convertTimeToMinutes = (hours, minutes) => {
    const h = hours ? parseInt(hours, 10) : 0;
    const m = minutes ? parseInt(minutes, 10) : 0;
    return h * 60 + m;
  };

  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');

  const handleHoursChange = (event) => {
    setHours(event.target.value);
    handleOnChangeTextTime(event.target.value, minutes);
  };

  const handleMinutesChange = (event) => {
    setMinutes(event.target.value);
    handleOnChangeTextTime(hours, event.target.value);
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={5}>
          <TextField
            disabled={false}
            label="Buscar"
            type="text"
            placeholder=""
            onChange={handleOnChangeText}
            error={false}
            helperText={false}
            fullWidth
          />
        </Grid>
        {tabActive == 1 && (
          <Grid item xs={12} sm={12} md={7} style={{ paddingLeft: '20px', display: 'flex', alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={1}>
              <label> Tiempo: </label>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Select value={selectedOption} onChange={handleChange} fullWidth>
                <MenuItem value="asc" selected={true}>Ascendente</MenuItem>
                <MenuItem value="desc">Descendente</MenuItem>
              </Select>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Filters;
