import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthLayout } from "../shared/Layout/AuthLayout";
import { UseFetch } from "../../shared/hooks/UseFetch";
import { useForm } from "../../shared/hooks/useForm";
import AppTheme from "../../styles/theme/AppTheme";
import Logo from "logo.png";
import { AlertDialog } from "shared/components/AlertDialog";

const formValidation = {
  username: [(value: any) => value.length >= 6, "El correo debe de tener un @."],
  password: [(value: any) => value.length >= 6, "Minimo 6 caracteres."],
};

const formData = {
  username: "",
  password: "",
};

export const LogIn = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");
  const [typeAlert, setTypeAlert] = useState("");

  const [sendToHome, setSendToHome] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { status } = useSelector((state: any) => state.auth);

  const {
    username,
    password,
    onInputChange,
    formState,
    usernameValid,
    passwordValid,
    isFormValid,
  } = useForm(formData, formValidation);

  const option = (body: any) => {
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  }

  const { data, getFetch } = UseFetch("login/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formState),
  });

  const isAuthenticating = useMemo(() => status === "checking", [status]);
  const onSubmit = async (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);
    getFetch().then((response: any) => {
      if (response.data.detail) {
        setTypeAlert("error");
        setContent(
          response.data.detail
        );
        setOpenDialog(true);
        return;
      }

      saveToken(response.data);
    }, error => {
      setTypeAlert("error");
      setContent(
        'Error en el servidor.'
      );
      setOpenDialog(true);
    });
  };

  const saveToken = (response: any) => {
    if (response && response.token) {
      localStorage.setItem('Token', response.token);
      localStorage.setItem('name', response.name);
      localStorage.setItem('role', response.role);
      localStorage.setItem('email', response.email);
      window.location.reload();
    }
  }

  return (
    <>
      <AlertDialog
        type={typeAlert}
        content={content}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
      <div className="bodyLogIn">
        <AppTheme>
          <AuthLayout title="">
            <div style={{ textAlign: 'center' }}>
              <img style={{ width: '50%', height: '80%' }} src={Logo} alt="Logo" />
            </div>

            <form onSubmit={onSubmit}>
              <Grid container>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <TextField
                    disabled={isAuthenticating}
                    label="Nombre de usuario"
                    type="name"
                    placeholder=" mail@hotmail.com "
                    fullWidth
                    name="username"
                    value={username}
                    onChange={onInputChange}
                    error={!!usernameValid && formSubmitted}
                  // helperText={!usernameValid }
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <TextField
                    disabled={isAuthenticating}
                    label="Contraseña"
                    type="password"
                    placeholder="Contraseña"
                    fullWidth
                    name="password"
                    value={password}
                    onChange={onInputChange}
                    error={!!passwordValid && formSubmitted}
                  // helperText={!passwordValid}
                  />
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
                  <Grid item xs={12}>
                    <Button
                      disabled={isAuthenticating}
                      type="submit"
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: 'rgb(74 137 73)', color: 'white' }}

                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </AuthLayout>
        </AppTheme>
      </div>
    </>
  );
};
