import React, { useState, useEffect } from "react";
import {
  Button, Modal as ModalMaterial, Fade, Grid, TextField, Checkbox,
  FormControlLabel,
} from "@mui/material";
import { AlertDialog } from "shared/components/AlertDialog";
import { ModalLayout } from "shared/components/ModalLayout";
import { TabPanel } from "shared/components/TabPanel";
import { UseFetch } from "shared/hooks/UseFetch";

export const Modal = (props: any) => {

  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [name, setName] = useState("");
  const [active, setActive] = useState(true);

  const fetchSave = UseFetch(`${props.urlController}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const fetchUpdate = UseFetch(`${props.urlController}${props.editing}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const fetchById = UseFetch(`${props.urlController}${props.editing}/`);
  const handleClose = () => {
    closeView();
    props.setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (props.editing) {
      searchById(props.editing);
      props.setOpen(true);
    }
  }, [props.editing]);


  const searchById = (id: any) => {
    fetchById.getFetch().then((response: any) => {
      if (response.data) {
        const { data } = response;
        setName(data.name);
        setActive(data.active);
      }
    });
  };

  const isValid = () => {
    if (name && name !== "") {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);
    let params: any = {
      name: name.trim(),
      active: active,
    };
    if (props.editing) {
      fetchUpdate.getFetch(params).then(
        (response: any) => {
          if (response.data.type?.toLowerCase() != "error") {
            setTypeAlert("success");
            setContent(`Se ha editado con éxito.`);
            closeView();
          } else {
            props.setOpen(true);
            setTypeAlert("error");
            setContent(
              response.data.message
                ? response.data.message
                : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
                }.`
            );
            setOpenDialog(true);
          }
        },
        (error) => {
          props.setOpen(true);
          setTypeAlert("error");
          setContent(
            error.data.message
              ? error.data.message
              : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
              }.`
          );
        }
      );
    } else {
      await fetchSave
        .getFetch({ ...params })
        .then(
          (response: any) => {
            if (response.data.type?.toLowerCase() != "error") {
              setTypeAlert("success");
              setContent(`Se ha editado con éxito.`);
              closeView();
            } else {
              props.setOpen(true);
              setTypeAlert("error");
              setContent(
                response.data.message
                  ? response.data.message
                  : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
                  }.`
              );
              setOpenDialog(true);
            }
          },
          (error) => {
            props.setOpen(true);
            setTypeAlert("error");
            setContent(
              error.data.message
                ? error.data.message
                : `Se ha producido un error al ${props.editing ? "editar" : "guardar"
                }.`
            );
            setOpenDialog(true);
          }
        );
    }
    props.setRefreshUsers(Date.now())
  };

  const closeView = () => {
    onResetForm();
    // refreshDataMaterials();
    props.setOpen(false);
  };

  const onResetForm = () => {
    props.setEditing(null);
    setActive(true);
    setName('');
    setFormSubmitted(false);
  };


  const isError = (field: string) => {
    if (formSubmitted) {
      return (field === '' || !field)
    } else {
      return false;
    }
  }

  const handleActiveChange = (event: any) => {
    setActive(event.target.checked);
  };

  return (
    <>
      <AlertDialog
        type={typeAlert}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <ModalMaterial open={props.open} onClose={handleClose} closeAfterTransition>
        <Fade in={props.open}>
          <div className="modal-container">
            <ModalLayout
              title={
                props.editing
                  ? "Editar el remolques"
                  : "Alta de remolques"
              }
              width={"60%"}
              handleClose={handleClose}
            >
              <TabPanel>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={2} sx={{ minHeight: 100 }}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <TextField
                        label="Nombre"
                        type="text"
                        placeholder=""
                        fullWidth
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={isError(name)}
                        helperText={false}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Activo"
                        onChange={handleActiveChange}
                        checked={active}
                        name="active"
                      />
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <Grid
                container
                spacing={2}
                sx={{ mb: 2, mt: 1 }}
                style={{ bottom: "5%" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: "#afafaf", color: "white" }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!isValid()}
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </ModalLayout>
          </div>
        </Fade>
      </ModalMaterial>
    </>
  );
};
