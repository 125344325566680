import { useState, useEffect } from "react";
import { Button, Grid, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Delete, Search } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertDialog } from "../../../shared/components/AlertDialog";
import { UseFetch } from "../../../shared/hooks/UseFetch";
import { DateRange } from "../../../shared/components/DateRange";

const initDateRange: any = {
  startDate: new Date(),
  endDate: null,
  key: "selection",
};
export const HeaderStatistics = ({ setFilter, mostrarTotal, setMostrarTotal }: any) => {

  const [labelMostrarTotal, setLabelMostrarTotal] = useState('Mostrar total');
  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");

  const [dateRange, setDateRange] = useState(initDateRange);
  const [clients, setClients] = useState([]);
  const [materials, setMaterials] = useState([] as any[]);
  const [clientSelectedFilterId, setClientSelectedFilterId] = useState([] as any[]);
  const [materialSelectedFilterId, setMaterialSelectedFilterId] = useState([] as any[]);
  const [allMaterials, setAllMaterials] = useState([]);
  const fetchListClients = UseFetch("clients/?page_size=0");
  const [clientSelectedFilter, setClientSelectedFilter] = useState([]);
  const [materialSelectedFilter, setMaterialSelectedFilter] = useState([] as any[]);
  const [keyAutomComplete, setKeyAutocomplete] = useState(Math.random());
  const [keyMaterial, setKeyMaterial] = useState(Math.random());
  const { getFetch } = UseFetch("materials/?page_size=0");

  useEffect(() => {
    fetchListClients.getFetch().then((response) => {
      setClients(response.data);
    });

    getFetch().then((response) => {
      setAllMaterials(response.data);
      setMaterials(response.data);
    });
  }, []);

  const handleApplyFilters = (event: any) => {
    setFilter({
      ...dateRange,
      client_id: clientSelectedFilterId,
      material_id: materialSelectedFilterId,
    });
  };

  const handleCleanFilters = (event: any) => {
    const emptyDate = {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    };
    setDateRange(emptyDate);
    setClientSelectedFilterId([]);
    setMaterialSelectedFilterId([]);

    setClientSelectedFilter([]);
    setMaterialSelectedFilter([]);
    setKeyAutocomplete(Math.random());
    setKeyMaterial(Math.random());

    setFilter({ ...emptyDate, clientId: -1 });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSelect = (e: any, newValues: any) => {
    setMaterials(allMaterials);
    setMaterialSelectedFilterId([]);
    setMaterialSelectedFilter([]);
    setClientSelectedFilterId([]);
    setClientSelectedFilter([]);

    if (newValues) {
      let ids = newValues.map((v: any) => v.id)
      setClientSelectedFilterId(ids);
      setClientSelectedFilter(newValues);
      let materialsClient: any[] = [];
      newValues.forEach((value: any) => {
        const client: any = clients.find((x: any) => x.id == value.id);
        const material = client.materials.map((item: any) => item.material);
        materialsClient.push(...material);
        materialsClient = deleteDuplicates(materialsClient)
      });
      setMaterials(materialsClient);
      setKeyMaterial(Math.random());
    }
  };

  const deleteDuplicates = (materials: any[]) => {
    const seenIds = new Set();
    const uniqueMaterials = [];
    for (const material of materials) {
      if (!seenIds.has(material.id)) {
        seenIds.add(material.id);
        uniqueMaterials.push(material);
      }
    }

    return uniqueMaterials;
  }


  const handleMaterialSelect = (e: any, value: any[]) => {
    setMaterialSelectedFilter([]);
    setMaterialSelectedFilterId([]);
    if (value) {
      setMaterialSelectedFilterId(value);
      setMaterialSelectedFilter(value);
    }
  };

  const handleMostrarTotal = () => {
    if (mostrarTotal) {
      setLabelMostrarTotal('Mostrar totales');
      setMostrarTotal(!mostrarTotal);
      return;
    }
    setLabelMostrarTotal('Ocultar totales');
    setMostrarTotal(!mostrarTotal);
  }

  return (
    <>
      <AlertDialog
        type={"error"}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <Grid container>
        <Grid
          container
          style={{ width: "100%", display: "flex", marginTop: 10 }}
        >
          <Grid item xs={12} sm={12} md={3} marginRight={'10px'}>
            <Tooltip title="Seleccionar rango de fechas" placement="top">
              <DateRange dateRange={dateRange} setDateRange={setDateRange} />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12} md={3} marginRight={'10px'}>
            <Autocomplete
              multiple
              style={{ width: "100%" }}
              disablePortal
              disabled={false}
              key={keyAutomComplete}
              id="combo-box-demo"
              getOptionLabel={(option: any) => option.short_name ?? ""}
              onChange={handleSelect}
              options={clients}
              value={clientSelectedFilter}
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%" }}
                  {...params}
                  label="Seleccione un proveedor:"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} marginRight={'10px'}>
            <Autocomplete
              multiple
              style={{ width: "100%" }}
              disablePortal
              disabled={false}
              key={keyMaterial}
              id="combo-box-demo"
              getOptionLabel={(option: any) => option.name ?? ""}
              onChange={handleMaterialSelect}
              options={materials}
              value={materialSelectedFilter}
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%" }}
                  {...params}
                  label="Seleccione un material:"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={.5} marginRight={'10px'} alignContent={'center'}>
            <Tooltip title="Limpiar filtros" placement="top" >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCleanFilters}
                fullWidth
              >
                <Delete />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12} md={.5} marginRight={'10px'} alignContent={'center'}>
            <Tooltip title="Aplicar filtros" placement="top">
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                fullWidth
              >
                <Search />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12} md={2} marginRight={'10px'} alignContent={'center'}>
            <Tooltip title={labelMostrarTotal} placement="top" >
              <Button
                variant="contained"
                color="primary"
                onClick={handleMostrarTotal}
                fullWidth
              >
                {labelMostrarTotal}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ width: "100%", display: "flex", marginTop: 10 }}
        >
          <Grid item xs={12} sm={12} md={12}
            style={{ display: dateRange?.startDate && dateRange?.endDate ? '' : 'none' }}
          >
            {dateRange?.startDate && dateRange?.endDate && (
              <div style={{ paddingTop: 10 }}>
                Desde:{" "}
                {dateRange
                  ? dateRange?.startDate?.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  : ""}
                {" Hasta: "}
                {dateRange
                  ? dateRange?.endDate?.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  : ""}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
