import React, { useEffect, useState } from "react";
import { Modal } from "./Modal/Modal";
import Filters from "./Filters/Filters";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UseFetch } from "../../../shared/hooks/UseFetch";
import { Table } from "shared/components/Table/Table";
import { columns } from "./util/columns";
import { ResponsePaginator } from "app/types/common/ResponsePaginator";




const getFilters = (filter: any, pageSize = 10) => {
  let filtersString = `page_size=${pageSize}&active=${filter.check.toString()}`;
  if (filter.textFilter !== '') {
    filtersString += "&name=" + filter.textFilter;
  }
  return filtersString;
};

export const Trailer = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const [textFilter, setTextFilter] = useState('');

  const urlController = "trailers/";
  const [refresh, setRefresh] = useState(Infinity);

  const { getFetch } = UseFetch(`${urlController}?${getFilters({ check, textFilter }, rowsPerPage)}`);

  const [count, setCount] = useState(0);
  const [editing, setEditing] = useState(false);

  const [data, setData] = useState([] as any[]);
  const [originalData, setOriginalData] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setRefresh(Math.random());
  }, [currentPage])


  useEffect(() => {
    getData(`&page=${currentPage + 1}`);
  }, [refresh, rowsPerPage]);

  const getData = (queryParams?: string) => {
    getFetch(null, queryParams).then((response: ResponsePaginator) => {
      setCount(response.data.total_items)
      setData(response.data.results);
      setOriginalData(response.data.results);
    });
  };

  return (
    <>
      <h1>Remolques</h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Filters
          setTextFilter={setTextFilter}
          check={check}
          setRefresh={setRefresh}
          setCheck={setCheck}
          setOpen={setOpen}
        />
        <Modal
          setRefreshUsers={setRefresh}
          setData={setData}
          setCurrentPage={setCurrentPage}
          editing={editing}
          setEditing={setEditing}
          urlController={urlController}
          setOpen={setOpen}
          open={open}
        />
      </div>
      <hr />
      <Table
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        columns={columns}
        count={count}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setEditing={setEditing}
      />
    </>
  );
};
