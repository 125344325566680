import React, { useEffect, useState } from 'react'


import { ModalVehicles } from './Modal/ModalVehicles'
import { VehiclesHeader } from './VehiclesHeader/VehiclesHeader'

import { UseFetch } from '../../../shared/hooks/UseFetch'
import { ResponsePaginator } from 'app/types/common/ResponsePaginator'

import { columns } from './util/columns'
import { Table } from 'shared/components/Table/Table'





const getFilters = (filter: any, pageSize = 10) => {
  let filtersString = `page_size=${pageSize}`;
  if (filter.textFilter !== '') {
    filtersString += "&name=" + filter.textFilter;
  }
  return filtersString;
};


export const Vehicles = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [textFilter, setTextFilter] = useState('');
  const [refresh, setRefresh] = useState(Infinity);
  const { getFetch } = UseFetch(`vehicles/?${getFilters({ textFilter }, rowsPerPage)}`);

  const [vehiclesEditing, setVehiclesEditing] = useState(false)

  const [VehiclesData, setVehiclesData] = useState([] as any[])
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    setRefresh(Math.random())
  }, [currentPage]);

  useEffect(() => {
    getData(`&page=${currentPage + 1}`)
  }, [refresh, rowsPerPage])

  const getData = (queryParams?: string) => {
    getFetch(null, queryParams).then((response: ResponsePaginator) => {
      setCount(response.data.total_items)
      setVehiclesData(response.data.results);
    });
  };

  return (
    <>
      <h1>Vehículos</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} >
        <VehiclesHeader setTextFilter={setTextFilter} setRefresh={setRefresh} setOpen={setOpen} />
        <ModalVehicles
          setRefresh={setRefresh}
          setVehiclesData={setVehiclesData}
          setCurrentPage={setCurrentPage}
          vehiclesEditing={vehiclesEditing}
          setVehiclesEditing={setVehiclesEditing}
          setOpen={setOpen}
          open={open}
        />

      </div>
      <hr />
      <Table
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        columns={columns}
        data={VehiclesData}
        count={count}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setEditing={setVehiclesEditing}

      />
    </>
  )
}