import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Chart } from "./Chart";
import { StaticsTable } from "./Table/StaticsTable";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { HeaderStatistics } from "./HeaderStatistics";
import { TabPanel } from "../../../shared/components/TabPanel";
import { UseFetch } from "../../../shared/hooks/UseFetch";
import { ResponsePaginator } from "app/types/common/ResponsePaginator";

am4core.useTheme(am4themes_animated);

const formatDate = (currentDate: any) => {
  if (currentDate == '') {
    return '';
  }

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;

}
const getFilters = (filter: any) => {
  let filterString = '';
  if (!filter) {
    return filterString;
  }

  if (filter.client_id && filter.client_id.length > 0) {
    filterString = 'client_id=' + filter.client_id.join() + '&';
  }

  if (filter?.startDate && filter?.startDate != '' && filter?.endDate && filter?.endDate != '') {
    filterString += 'start_date=' + formatDate(filter?.startDate) + '&end_date=' + formatDate(filter?.endDate);
  }
  if (filter.material_id && filter.material_id.length > 0) {
    filterString += '&material_id=' + filter.material_id.map((m: any) => m.id).join();
  }
  return filterString;
}

export const Statistics = () => {
  const [tab, setTab] = useState(0);
  const [filter, setFilter] = useState(null);
  const { getFetch } = UseFetch("purchases/statics-report/?page_size=0&" + getFilters(filter));
  const handleChangeTab = (event: any, newValue: any) => {
    setTab(newValue)
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([] as any[]);
  const [mostrarTotal, setMostrarTotal] = useState(false);

  const getData = (queryParams?: string) => {
    getFetch(null, queryParams).then((response: any) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    getData(`?page=${currentPage + 1}`)
  }, [currentPage])


  useEffect(() => {
    getData();
    setCurrentPage(0);
  }, [filter])




  return (
    <>
      <HeaderStatistics setFilter={setFilter} mostrarTotal={mostrarTotal} setMostrarTotal={setMostrarTotal}></HeaderStatistics>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Inventario" />
          <Tab label="Estadísticas" />
        </Tabs>
      </Box>

      <TabPanel value={tab} index={1}>
        <Chart data={data} mostrarTotal={mostrarTotal} />
      </TabPanel>

      <TabPanel value={tab} index={0}>
        <StaticsTable data={data} mostrarTotal={mostrarTotal} />
      </TabPanel>
    </>
  );
};
