import { TextField, Grid, Button } from "@mui/material";


const Filters = ({ setTextFilter, setRefresh, setOpen }) => {

  const handleOnChangeText = (event) => {
    setTextFilter(event.target.value)
    setRefresh(Math.random())
  }

  return (

    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            disabled={false}
            label="Buscar usuario"
            type="text"
            placeholder=""
            onChange={handleOnChangeText}
            error={false}
            helperText={false}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)} style={{ float: 'right' }} fullWidth>
            Agregar usuario
          </Button>
        </Grid>
      </Grid>

    </>
  )
}

export default Filters