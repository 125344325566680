import React, { useEffect, useState } from "react";
import { Button, Modal, Fade, Grid, TextField } from "@mui/material";
import { ModalLayout } from "shared/components/ModalLayout";
import { TodoForm } from "./TodoForm";
import { UseFetch } from "shared/hooks/UseFetch";
import { AlertDialog } from "shared/components/AlertDialog";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { RoleEnum } from "shared/enums/roleEnum";
import { KeyHandles } from "shared/handlers/KeyHandles";
import { AuthService } from "app/services/AuthService";
import { TodoList } from "./TodoList";
import { useNavigate } from 'react-router-dom';

export const ModalPurchase = ({
  setRefreshList,
  setOpen,
  open,
  purchase,
  setPurchase,
  clients,
  setLoading,
  role,
  preSelectedIdProveedor,
  preSelectedIdDriver,
  preSelectedIdTrailer,
  preSelectedIdVehicle,
  travelId
}) => {
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [units, setUnits] = useState([]);

  const [clientSelectedId, setClientSelectedId] = useState("");

  const [clientSelected, setClientSelected] = useState(null);
  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [driverSelected, setDriverSelected] = useState(null);
  const [trailerSelected, setTrailerSelected] = useState(null);


  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [trailers, setTrailers] = useState([]);

  //materials of select list
  const [materials, setMaterials] = useState([]);

  //Material to update in purchase
  const [newMaterial, setNewMaterial] = useState({});

  //Array of materials for any purchase
  const [materialsDetails, setMaterialsDetails] = useState([]);

  const [header, setHeader] = useState({ date: moment() });
  const [purchaseDetail, setPurchaseDetail] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [resetForm, setResetForm] = useState(Infinity);

  const fetchVehicles = UseFetch("vehicles/?page_size=0");
  const fetchDriver = UseFetch("drivers/?page_size=0&active=true");
  const fetchTrailer = UseFetch("trailers/?page_size=0&active=true");
  const fetchSavePurchase = UseFetch("purchases/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const fetchClientDetail = UseFetch("clients/" + clientSelectedId);
  const fetchPurchaseDetail = UseFetch(
    "purchases/?page_size=0&purchase_id=" + purchase?.purchase_id
  );
  const fetchEditPurchase = UseFetch(`purchases/${purchase?.purchase_id}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const fetchUpdateTravel = UseFetch('collections/', {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const fetchUnitsOfMeasure = UseFetch("materials/units-of-measure?page_size=0");
  const initFetchUnitsOfMeasure = () => {
    fetchUnitsOfMeasure.getFetch().then((response) => {
      setUnits(response.data);
    });
  };

  useEffect(() => {
    if (clients.length > 0) {
      if (preSelectedIdProveedor) {
        const client = clients.find((e) => e.id == preSelectedIdProveedor);
        if (client) {

          setClientSelected(client);
          setClientSelectedId(preSelectedIdProveedor);
        }
      }
    }
  }, [clients])

  useEffect(() => {
    const load = async () => {
      if (open) {
        initVehicles();
        initDrivers();
        initTrailers();
        initFetchUnitsOfMeasure();
      }
    };
    load();
  }, [open]);

  const initVehicles = () => {
    fetchVehicles.getFetch().then((response) => {
      setVehicles(response.data);
      if (preSelectedIdVehicle) {
        const obj = response.data.find((e) => e.id == preSelectedIdVehicle);
        setVehicleSelected(obj);
      }
    });
  };

  const initDrivers = () => {
    fetchDriver.getFetch().then((response) => {
      setDrivers(response.data);
      if (preSelectedIdDriver) {
        const driver = response.data.find((e) => e.id == preSelectedIdDriver);
        setDriverSelected(driver);
      }
    });
  };

  const initTrailers = () => {
    fetchTrailer.getFetch().then((response) => {
      setTrailers(response.data);
      if (preSelectedIdTrailer) {
        const obj = response.data.find((e) => e.id == preSelectedIdTrailer);
        setTrailerSelected(obj);
      }
    });
  };

  const getPurchase = () =>
    new Promise(async (resolve) => {
      fetchPurchaseDetail.getFetch().then((response) => {
        const { data } = response;
        if (data) {
          setPurchaseDetail(data);
          const headersResponse = data[0];
          setClientSelectedId(headersResponse.client.id);
          const a = clients.find(
            (client) => client.id === headersResponse.client.id
          );
          setClientSelected(a);
          const headers = {
            date: moment(headersResponse.formated_date, "YYYY-MM-DD"),
            outbound_reference_number:
              headersResponse.outbound_reference_number,
            vehicle: headersResponse.vehicle.id,
            trailer: headersResponse.trailer,
            driver: headersResponse.driver,
          };

          setVehicleSelected(headersResponse.vehicle);
          setDriverSelected(headersResponse.driver);
          setTrailerSelected(headersResponse.trailer);

          setHeader(headers);
          resolve(data);
        }
      });
    });

  useEffect(() => {
    if (clientSelectedId) {
      fetchClientDetail.getFetch().then((response) => {

        setMaterials(response.data.materials);

        if (purchase) {
          setMaterialsOfPurchase();
        }
      });
    }
  }, [clientSelectedId]);

  useEffect(() => {
    if (purchase) {
      getPurchase();
    }
  }, [purchase]);

  const setMaterialsOfPurchase = () => {
    const materialsOfPurchase = purchaseDetail.map((purchaseFound) => {
      console.log(purchaseFound)
      return {
        id: purchaseFound.id,
        control_number: purchaseFound.control_number,
        // material: material.material,
        materialName: purchaseFound.material_name,
        notes: purchaseFound.notes,
        price: purchaseFound.price,
        driver: purchaseFound.driver,
        provider_ticket: purchaseFound.provider_ticket,
        ticket: purchaseFound.ticket,
        weight: purchaseFound.weight,
        unit_of_measure: purchaseFound.client.materials.find((e)=> e.material.id === purchaseFound.material ).unit_of_measure.abbreviation

      };
    });
    setMaterialsDetails(materialsOfPurchase);
  };

  const handleClose = () => {
    setOpen(false);
    resetData();
  };

  const isValidHeaders = () => {
    if (clientSelected &&
      header.date &&
      (header.outbound_reference_number || header.outbound_reference_number == '0') &&
      vehicleSelected?.id &&
      driverSelected?.id) {
      return true;
    }

    return false;
  }
  const isValid = () => {

    if (!isValidHeaders()) {
      setShowErrors(true);
      return;
    }

    if (materialsDetails) {
      let isValid;

      let shouldSkip = false;
      materialsDetails.forEach((element) => {
        if (shouldSkip) {
          return;
        }
        isValid = isValidMaterial(element);
        if (!isValid) {
          shouldSkip = true;
          return;
        }
      });
      setShowErrors(!isValid);
      return isValid;
    }
  };



  const isValidMaterial = (material) => {
    console.log(material);
    if (
      (material.price || material.price.toString() == "0") &&
      material.weight &&
      material.weight > 0 &&
      (material.unit_of_measure == 'pz' || (material.ticket || material.ticket.toString() == "0")) &&
      (material.control_number || material?.control_number.toString() == "0") &&
      (header.outbound_reference_number ||
        header.outbound_reference_number?.toString() == "0")
    ) {
      return true;
    } else {
      return false;
    }
  };



  const onSubmit = async (event) => {
    if (!isValid()) {
      let messageError
      if (materialsDetails.length > 0) {
        messageError = "Debes corregir los campos en rojo antes de guardar."
      } else {
        messageError = "Debes tener mínimo un material capturado"
      }
      // let messageError = materialsToValid.length > 0 ? "Debes corregir los campos en rojo antes de guardar." : "Debes tener mínimo un material capturado"
      setTypeAlert("error");
      setContent(
        messageError
      );
      setOpenDialog(true);
      return;
    }

    setOpen(false);
    const isConfirmed = await showCorfimationAlert();
    setLoading(true);
    if (isConfirmed) {
      event.preventDefault();

      await savePurchase();
    } else {
      setOpen(true);
    }
    setLoading(false);
  };

  const getRole = () => {
    const token = localStorage.getItem("Token");
    const decodedToken = jwtDecode(token);
    return decodedToken.role;
  };

  const getBody = (isEdit) => {
    let request;
    if (isEdit) {
      const materialsDetail = materialsDetails.filter(
        (material) => material.ticket || material.ticket?.toString() == "0"
      );
      request = {
        client: clientSelectedId,
        creator: AuthService.getCurrentUserId(),
        outbound_reference_number: header.outbound_reference_number,
        date: header.date.format("YYYY-MM-DD"),
        vehicle: vehicleSelected.id,
        trailer: trailerSelected?.id,
        driver: driverSelected.id,
        detail_purchase: materialsDetail,
      };
    } else {
      const materialsDetail = materialsDetails.filter(
        (material) => material.ticket || material.ticket?.toString() == "0"
      );
      request = {
        client: clientSelectedId,
        creator: AuthService.getCurrentUserId(),
        outbound_reference_number: header.outbound_reference_number,
        date: header.date.format("YYYY-MM-DD"),
        vehicle: vehicleSelected.id,
        trailer: trailerSelected?.id,
        driver: driverSelected.id,
        detail_purchase: materialsDetail,
      };
    }

    if (travelId && travelId > 0) {
      request.collection_id = travelId;
    }
    return request;
  };

  const savePurchase = async () => {
    const fetch = purchase ? fetchEditPurchase : fetchSavePurchase;
    const body = getBody(purchase !== null);

    fetch.getFetch(body).then(
      (response) => {
        if (response.data.type?.toLowerCase() != "error") {
          updateCollection(travelId)
          setTypeAlert("success");
          setContent(`Se ha ${purchase ? "editado" : "guardado"} con éxito.`);
          resetData();
        } else {
          setOpen(true);
          setKey(Math.random());
          setTypeAlert("error");
          setContent(
            response.data.message
              ? response.data.message
              : `Se ha producido un error al ${purchase ? "editar" : "guardar"
              }.`
          );
        }
        setOpenDialog(true);
      },
      (error) => {
        setTypeAlert("error");
        setContent(
          `Se ha producido un error al ${purchase ? "editar" : "guardar"}.`
        );
        setOpenDialog(true);
        resetData();
      }
    );
  };

  const isError = (field) => !field && showErrors;

  const resetData = () => {

    setClientSelected(null);
    setVehicleSelected(null);
    setDriverSelected(null);
    setTrailerSelected(null);
    setMaterials([]);
    setMaterialsDetails([]);
    setClientSelectedId("");
    setRefreshList(Math.random());
    setPurchase(null);
    setHeader({ date: moment() });
    setClientSelected(null);
    setShowErrors(false);
  };

  const handleChangeClient = (event, value) => {
    setResetForm(Math.random());
    setClientSelectedId(value?.id);
    setClientSelected(value);
  };

  const handleChangeVehicle = (event, value) => {
    setVehicleSelected(value);
  };

  const handleChangeDriver = (event, value) => {
    setDriverSelected(value);
  };

  const handleChangeTrailer = (event, value) => {
    setTrailerSelected(value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    const date = moment(inputValue, "YYYY-MM-DD", true);

    setHeader({ ...header, date: date });
  };

  const showCorfimationAlert = () => {
    let ids = materialsDetails.map((material) => material.ticket);
    ids = ids.filter((material) => material || material?.toString() == "0");

    let idsString = ids.join(",");
    idsString = idsString.endsWith(",")
      ? idsString.substring(0, idsString.length - 1)
      : idsString;

    return new Promise((resolve) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: "Confirmación de entrada",
        icon: "question",
        text: `Los siguientes folios se guardarán: ${idsString}`,
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#b6f59d",
      }).then((result) => {
        resolve(result.isConfirmed);
      });
    });
  };

  const updateCollection = (id) => {
    let obj = {
      status: 4
    }


    if (obj != null) {
      fetchUpdateTravel.getFetch(obj, `${id}/`).then(() => {
        navigate(`/compras`);
      });
    }
  }
  return (
    <>
      <AlertDialog
        type={typeAlert}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <Modal open={open} onClose={handleClose} closeAfterTransition style={{ overflowY: 'auto' }}>
        <Fade in={open}>
          <div className="modal-container">
            <ModalLayout
              width="95vw"
              style={{ minWidth: "0px!important" }}
              title={
                purchase
                  ? `Editando orden salida #${purchase.outbound_reference_number}, folio ticket #${purchase.ticket}`
                  : "Registro de entradas"
              }
              handleClose={handleClose}
            >
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    disablePortal
                    disabled={purchase !== null}
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.short_name}
                    onChange={handleChangeClient}
                    options={clients}
                    value={clientSelected}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        {...params}
                        label="Selecciona un proveedor:"
                        error={isError(clientSelected)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    className="date-picker"
                    label="Fecha"
                    type="date"
                    fullWidth
                    name="date"
                    onChange={handleDateChange}
                    value={header.date ? header.date.format("YYYY-MM-DD") : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    disabled={
                      purchase !== null && getRole() === RoleEnum.Usuario
                    }
                    label="Folio salida"
                    type="text"
                    placeholder="Escribir folio salida aquí"
                    fullWidth
                    name="outbound_reference_number"
                    key={key}
                    onChange={(e) => {
                      setHeader({
                        ...header,
                        outbound_reference_number: e.target.value,
                      });
                    }}
                    value={header.outbound_reference_number ?? ""}
                    error={
                      !header.outbound_reference_number &&
                      header.outbound_reference_number?.toString() != "0" &&
                      showErrors
                    }
                    helperText={false}
                    defaultChecked={true}
                    onKeyDown={KeyHandles(true).keyDownOnlyNumberHandle}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={1} paddingTop={1}>
                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    onChange={handleChangeVehicle}
                    options={vehicles}
                    value={vehicleSelected}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        {...params}
                        label="Selecciona un vehiculo:"
                        error={isError(vehicleSelected?.id)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    onChange={handleChangeDriver}
                    options={drivers}
                    value={driverSelected}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        {...params}
                        label="Selecciona un chofer:"
                        error={isError(driverSelected?.id)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    onChange={handleChangeTrailer}
                    options={trailers}
                    value={trailerSelected}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "100%" }}
                        {...params}
                        label="Selecciona un remolque:"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Divider orientation="vertical" variant="middle" flexItem />
              <TodoForm
                setNewMaterial={setNewMaterial}
                newMaterial={newMaterial}
                materials={materials}
                resetForm={resetForm}
                setResetForm={setResetForm}
                materialsDetails={materialsDetails}
                role={role}
                setMaterialsDetails={setMaterialsDetails}
                units={units}
              />

              <Divider orientation="vertical" variant="middle" flexItem />

              <div
                style={{
                  overflow: "auto",
                  display: "block",
                  overflowY: "scroll",
                  height: "55vh",
                }}
              >
                {materials.length > 0 && <h5>Materiales:</h5>}

                <TodoList
                  role={role}
                  materialsDetails={materialsDetails}
                  setMaterialsDetails={setMaterialsDetails}
                  units={units}
                />
              </div>

              <Grid
                container
                spacing={2}
                sx={{ mb: 2, mt: 1 }}
                style={{ bottom: "auto", width: "100%" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: "#afafaf", color: "white" }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    onClick={onSubmit}
                    variant="contained"
                    fullWidth
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </ModalLayout>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
