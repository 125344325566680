import { useState, useEffect } from "react";
import {
    Button,
    Modal,
    Fade,
    Grid,
    TextField,
    Autocomplete,
} from "@mui/material";
import { useForm } from "shared/hooks/useForm";
import { ModalLayout } from "shared/components/ModalLayout";
import { AlertDialog } from "shared/components/AlertDialog";
import { UseFetch } from "shared/hooks/UseFetch";

const formValidation = {
    company_name: [(value: any) => value.length >= 6, "El correo debe de tener un @."],
    // company_name: [(value) => value.includes("@"), "El correo debe de tener un @."],
    short_name: [(value: any) => value.length >= 6, "Minimo 6 caracteres."],
};

const formData = {
    company_name: "",
    short_name: "",
    materials: [],
    active: true,
};
function getDate() {
    let currentDate = new Date();

    // Obtener el año, mes y día por separado
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11, por lo tanto se suma 1
    let day = String(currentDate.getDate()).padStart(2, '0');

    // Formatear la fecha en formato YYYY-MM-DD
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}
export const ModalAdd = (props: any) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [content, setContent] = useState("");
    const [typeAlert, setTypeAlert] = useState("");
    const [todoItems, setTodoItems] = useState([]);
    const [showErrors, setShowErrors] = useState(false);

    const [clientSelected, setClientSelected] = useState(null as any);
    const [driverSelected, setDriverSelected] = useState(null as any);
    const [trailerSelected, setTrailerSelected] = useState(null as any);
    const [vehicleSelected, setVehicleSelected] = useState(null as any);
    const [scheduleDate, setScheduleDate] = useState(getDate() as any)
    const [initialDateTime, setInitialDateTime] = useState(null as any)
    const [endDateTime, setEndDateTime] = useState(null as any)
    const [status, setStatus] = useState(0)

    const fetchById = UseFetch("collections/" + props.idEdit);

    const fetchUpdate = UseFetch('collections/detail/', {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
    });
    useEffect(() => {
        //Mandar a leer uno y editar.
        setEndDateTime(null);
        setInitialDateTime(null);
        if (props.idEdit > 0) {
            getCollectionById(props.idEdit);
        }
    }, [props.idEdit, props.open])

    useEffect(() => {
        setScheduleDate(getDate())
        setClientSelected(null);
        setDriverSelected(null);
        setTrailerSelected(null);
        setVehicleSelected(null);
        setShowErrors(false);
    }, [props.refresh])


    const fetchSave = UseFetch("collections/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    });


    const getCollectionById = (id: any) => {
        fetchById.getFetch().then((response) => {
            if (response.data) {
                const client = props.clients.find((e: any) => e.id === response.data.client.id);
                const driver = props.drivers.find((e: any) => e.id === response.data.driver.id);
                const trailer = props.trailers.find((e: any) => e.id === response.data.trailer.id);
                const vehicle = props.vehicles.find((e: any) => e.id === response.data.vehicle.id);

                setStatus(response.data.status)
                if (client) {
                    setClientSelected(client);
                }

                if (driver) {
                    setDriverSelected(driver);
                }

                if (trailer) {
                    setTrailerSelected(trailer);
                }

                if (vehicle) {
                    setVehicleSelected(vehicle);
                }

                if (response.data.scheduled_date) {
                    setScheduleDate(response.data.scheduled_date)
                }
                if (response.data.start_time_zone) {
                    setInitialDateTime(response.data.start_time_zone.slice(0, 16))
                }
                if (response.data.end_time_zone) {
                    setEndDateTime(response.data.end_time_zone.slice(0, 16))
                }
            }
        });
    }
    const {
        company_name,
        short_name,
        materials,
        active,
        onInputChange,
        formState,
        onResetForm,
        setFormState,
        onCheckChange,
    } = useForm(formData, formValidation);

    const handleChangeScheduleDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScheduleDate(event.target.value);
    };

    const handleChangeClient = (event: any, value: any) => {
        setClientSelected(value);
    };

    const handleChangeDriver = (event: any, value: any): void => {
        setDriverSelected(value);
    };

    const handleChangeTrailer = (event: any, value: any): void => {
        setTrailerSelected(value);
    };

    const handleChangeVehicle = (event: any, value: any): void => {
        setVehicleSelected(value);

    };

    const handleClose = () => {
        closeView();
        props.setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const closeView = () => {
        onResetForm();
        setTodoItems([]);
        props.setOpen(false);
        props.setRefreshList(Math.random());
        props.setOpen(false);
    };

    const handleTodoFormSubmit = (values: any) => {
        setTodoItems(values);
    };
    const isError = (field: any) => !field && showErrors;

    const isDisable = () => {
        return false;
    };

    const onSubmit = () => {
        const fetch = fetchSave;
        if (driverSelected && clientSelected && vehicleSelected && trailerSelected && scheduleDate) {
            let body: any = {
                trailer_id: trailerSelected.id,
                client_id: clientSelected.id,
                vehicle_id: vehicleSelected.id,
                driver_id: driverSelected.id,
                scheduled_date: scheduleDate
            };
            if (props.idEdit) {
                if (initialDateTime) {
                    body.start_time = initialDateTime;
                }
                if (endDateTime) {
                    body.end_time = endDateTime;
                }

                fetchUpdate.getFetch(body, `${props.idEdit}/`).then(
                    (response) => {
                        if (response.data.type?.toLowerCase() != "error") {
                            setTypeAlert("success");
                            setContent(`Se ha guardado con éxito.`);
                            setOpenDialog(true);
                            resetData();
                            props.setOpen(false);
                        } else {
                            setTypeAlert("error");
                            setContent(
                                response.data.message
                                    ? response.data.message
                                    : `Se ha producido un error al guardar.`
                            );
                        }
                        setOpenDialog(true);
                        props.setIdEdit(0);
                    },
                    (error) => {
                        setTypeAlert("error");
                        setContent(
                            `Se ha producido un error al guardar.`
                        );
                        setOpenDialog(true);
                    }
                );
            } else {
                fetch.getFetch(body).then(
                    (response) => {
                        if (response.data.type?.toLowerCase() != "error") {
                            setTypeAlert("success");
                            setContent(`Se ha guardado con éxito.`);
                            setOpenDialog(true);
                            resetData();
                            props.setOpen(false);
                        } else {
                            setTypeAlert("error");
                            setContent(
                                response.data.message
                                    ? response.data.message
                                    : `Se ha producido un error al guardar.`
                            );
                        }
                        setOpenDialog(true);
                    },
                    (error) => {
                        setTypeAlert("error");
                        setContent(
                            `Se ha producido un error al guardar.`
                        );
                        setOpenDialog(true);
                    }
                );
            }
        } else {
            setShowErrors(true);
        }
    }

    const resetData = () => {
        setTrailerSelected(null);
        setDriverSelected(null);
        setVehicleSelected(null);
        setClientSelected(null);
    }

    return (
        <>
            <AlertDialog
                type={typeAlert}
                content={content}
                open={openDialog}
                handleClose={handleCloseDialog}
            />
            <Modal open={props.open} onClose={handleClose} closeAfterTransition >
                <Fade in={props.open}>
                    <div className="modal-container" >
                        <ModalLayout
                            title={'Programar recoleccion'}
                            width={"60%"}
                            handleClose={handleClose}
                        >
                            <form onSubmit={() => { }}>
                                <Grid container spacing={2} sx={{ minHeight: 100 }
                                }>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                                        <Autocomplete
                                            style={{ width: "100%" }}
                                            disablePortal
                                            id="combo-box-demo"
                                            getOptionLabel={(option: any) => option.short_name}
                                            onChange={handleChangeClient}
                                            options={props.clients}
                                            value={clientSelected}
                                            disabled={status === 4}
                                            renderInput={(params) => (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    {...params}
                                                    label="Selecciona un proveedor:"
                                                    error={isError(clientSelected)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                                        <Autocomplete
                                            style={{ width: "100%" }}
                                            disablePortal
                                            id="combo-box-demo"
                                            getOptionLabel={(option: any) => option.name}
                                            onChange={handleChangeDriver}
                                            options={props.drivers}
                                            value={driverSelected}
                                            renderInput={(params) => (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    {...params}
                                                    label="Selecciona un chofer:"
                                                    error={isError(driverSelected)}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                                        <Autocomplete
                                            style={{ width: "100%" }}
                                            disablePortal
                                            id="combo-box-demo"
                                            getOptionLabel={(option: any) => option.name}
                                            onChange={handleChangeVehicle}
                                            options={props.vehicles}
                                            value={vehicleSelected}
                                            renderInput={(params) => (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    {...params}
                                                    label="Selecciona un vehiculo:"
                                                    error={isError(vehicleSelected)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                                        <Autocomplete
                                            style={{ width: "100%" }}
                                            disablePortal
                                            id="combo-box-demo"
                                            getOptionLabel={(option: any) => option.name}
                                            onChange={handleChangeTrailer}
                                            options={props.trailers}
                                            value={trailerSelected}
                                            renderInput={(params) => (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    {...params}
                                                    label="Selecciona un remolque:"
                                                    error={isError(trailerSelected)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            className="date-picker"
                                            label="Fecha"
                                            type="date"
                                            fullWidth
                                            name="date"
                                            onChange={handleChangeScheduleDate}
                                            value={scheduleDate ? scheduleDate : ""}
                                            error={isError(scheduleDate)}
                                        />
                                    </Grid>

                                    {props.idEdit > 0 && (
                                        <>
                                            {initialDateTime && (
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        className="datetime-picker"
                                                        label="Fecha y Hora inicial"
                                                        type="datetime-local"
                                                        fullWidth
                                                        name="datetime"
                                                        onChange={(event) => setInitialDateTime(event.target.value)}
                                                        value={initialDateTime ? initialDateTime : ""}
                                                        error={isError(initialDateTime)}
                                                    />
                                                </Grid>
                                            )}
                                            {endDateTime && (
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        className="datetime-picker"
                                                        label="Fecha y Hora final"
                                                        type="datetime-local"
                                                        fullWidth
                                                        name="datetime"
                                                        onChange={(event) => setEndDateTime(event.target.value)}
                                                        value={endDateTime ? endDateTime : ""}
                                                        error={isError(endDateTime)}
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </form>
                            <Grid
                                container
                                spacing={2}
                                xs={12}
                                sx={{ mb: 2, mt: 1 }}
                                style={{ bottom: "5%" }}
                            >
                                <Grid item xs={6}>
                                    <Button
                                        onClick={handleClose}
                                        disabled={false}
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        style={{ backgroundColor: '#afafaf', color: 'white' }}
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        disabled={isDisable()}
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        onClick={onSubmit}
                                    >
                                        Guardar
                                    </Button>
                                </Grid>
                            </Grid>
                        </ModalLayout>
                    </div>
                </Fade>
            </Modal >
        </>
    );
};
