import React from "react";

import { NavLink } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import { Recycling } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import { RoleEnum } from "shared/enums/roleEnum";
import FireTruckIcon from '@mui/icons-material/FireTruck';

const getRole = () => {
  const token = localStorage.getItem("Token");
  const decodedToken = jwtDecode(token);
  return decodedToken.role;
};

export const Buttons = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
      <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ display: getRole() === RoleEnum.Usuario ? "none" : "" }}
        >
          <NavLink className="nav-link" to="/choferes">
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ height: "200px" }}
              fullWidth
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <AirlineSeatReclineNormalIcon
                    fontSize="large"
                    style={{ marginRight: 8 }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <h6>Choferes</h6>
                </div>
              </div>
            </Button>
          </NavLink>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ display: getRole() === RoleEnum.Usuario ? "none" : "" }}
        >
          <NavLink className="nav-link" to="/vehiculos">
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ height: "200px" }}
              fullWidth
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <DirectionsCarIcon
                    fontSize="large"
                    style={{ marginRight: 8 }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <h6>Vehículos</h6>
                </div>
              </div>
            </Button>
          </NavLink>
        </Grid>



        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ display: getRole() === RoleEnum.Usuario ? "none" : "" }}
        >
          <NavLink className="nav-link" to="/materiales">
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ height: "200px" }}
              fullWidth
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Recycling
                    fontSize="large"
                    style={{ marginRight: 8 }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <h6>Materiales</h6>
                </div>
              </div>
            </Button>
          </NavLink>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ display: getRole() === RoleEnum.Usuario ? "none" : "" }}
        >
          <NavLink className="nav-link" to="/remolques">
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ height: "200px" }}
              fullWidth
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <FireTruckIcon
                    fontSize="large"
                    style={{ marginRight: 8 }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <h6>Remolques</h6>
                </div>
              </div>
            </Button>
          </NavLink>
        </Grid>




       

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ display: getRole() === RoleEnum.Usuario ? "none" : "" }}
        >
          <NavLink className="nav-link" to="/clientes">
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ height: "200px" }}
              fullWidth
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <StorefrontIcon fontSize="large" style={{ marginRight: 8 }} />
                </div>
                <div style={{ marginTop: 10 }}>
                  <h6>Proveedores</h6>
                </div>
              </div>
            </Button>
          </NavLink>
        </Grid>


        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ display: getRole() === RoleEnum.Usuario ? "none" : "" }}
        >
          <NavLink className="nav-link" to="/usuarios">
            <Button
              color="sixth"
              variant="contained"
              size="large"
              style={{ height: "200px" }}
              fullWidth
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <StackedLineChartIcon
                    fontSize="large"
                    style={{ marginRight: 8 }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <h6>Usuarios</h6>
                </div>
              </div>
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </div>
  );
};
