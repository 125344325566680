import jwtDecode from "jwt-decode";

const getTokenDecoded = (): any => {
    const token = localStorage.getItem("Token") ?? '';
    const decodedToken = jwtDecode(token);
    return decodedToken;
}

export const AuthService = {
    getCurrentRole: () => {
        return getTokenDecoded().role;
    },
    getCurrentUserId: () => {
        return getTokenDecoded().user_id;
    }
}