import { useState, useEffect } from "react";
import { TextField, Grid, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import jwtDecode from "jwt-decode";
import { RoleEnum } from "shared/enums/roleEnum";
import { KeyHandles } from "shared/handlers/KeyHandles";

export const TodoForm = ({
  setNewMaterial,
  newMaterial,
  materials,
  resetForm,
  setResetForm,
  materialsDetails,
  setMaterialsDetails,
  role
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [valid, setValid] = useState(true);
  const [isPz, setIsPz] = useState(false);

  useEffect(() => {
    setSelectedValue(null);
    setEmptyMaterial();
  }, [resetForm]);

  const isValid = (material) => {
    console.log(material);
    if (
      (material.price || material.price.toString() === '0') &&
      (material.weight && material.weight > 0) &&
      (material.unit_of_measure == 'pz' || (material.ticket || material.ticket.toString() === '0')) &&
      (material.control_number || material.control_number.toString() === '0')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    if (!isValid(newMaterial)) {
      setShowErrors(true);
      return;
    }

    setMaterialsDetails([...materialsDetails, newMaterial]);
    setEmptyMaterial();
    setSelectedValue(null);
  };

  const getRole = () => {
    const token = localStorage.getItem("Token");
    const decodedToken = jwtDecode(token);
    return decodedToken.role;
  };

  const isDisabled = () => {
    return !newMaterial.material;
  };

  const handleOnChange = (material) => {
    setValid(!isValid(material));
    setNewMaterial(material);
  };

  const showError = (field) => {
    const error = newMaterial.material && !field;
    return error;
  };

  const setEmptyMaterial = (newMaterial) => {
    setNewMaterial({
      material: newMaterial ? newMaterial.material.id : '',
      materialName: newMaterial ? newMaterial.material.name : '',
      price: newMaterial ? newMaterial.price : '',
      weight: '',
      ticket: '',
      notes: '',
      control_number: '',
      unit_of_measure: ''
    });
  };

  const handleMaterialAutocompleteChange = (event, material) => {

    setSelectedValue(material);
    if (material) {
      setEmptyMaterial(material);
      if (material.unit_of_measure.abbreviation === "pz") {
        setNewMaterial((prevMaterial) => ({
          ...prevMaterial,
          ticket: "0",

          unit_of_measure: "pz"
        }));
        setIsPz(true);
      } else {
        setIsPz(false);
      }
    }
  };

  return (
    <div>
      <form style={{ paddingTop: '10px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={2.5}>
            <Autocomplete
              style={{ width: "100%" }}
              disablePortal
              id="combo-box-demo"
              key={(option) => option?.material?.id}
              getOptionLabel={(option) => option?.material?.name}
              onChange={handleMaterialAutocompleteChange}
              options={materials}
              value={selectedValue}
              renderInput={(params) => (
                <TextField
                  key={params.id}
                  style={{ width: "100%" }}
                  {...params}
                  label="Selecciona un material:"
                />
              )}
            />
          </Grid>
          {!isPz && (
            <Grid item xs={12} sm={12} md={1.5}>
              <TextField
                disabled={isDisabled()}
                type="text"
                onChange={(e) =>
                  handleOnChange({ ...newMaterial, ticket: e.target.value })
                }
                value={newMaterial?.ticket}
                name="ticket"
                label="Folio ticket"
                fullWidth
                onKeyDown={KeyHandles().keyDownOnlyNumberHandle}
                error={showError(newMaterial?.ticket)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={1}>
            <TextField
              disabled={isDisabled()}
              type="number"
              onChange={(e) =>
                handleOnChange({
                  ...newMaterial,
                  weight: e.target.value < 0 ? 0 : e.target.value,
                })
              }
              value={newMaterial?.weight}
              name="weight"
              label="Cantidad"
              fullWidth
              error={showError(newMaterial?.weight) || newMaterial?.weight === '0'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1}>
            <TextField
              disabled={isDisabled("Price") || role === RoleEnum.Usuario}
              type="number"
              value={newMaterial?.price}
              onChange={(e) =>
                handleOnChange({
                  ...newMaterial,
                  price: e.target.value < 0 ? 0 : e.target.value,
                })
              }
              name="price"
              label="Precio"
              fullWidth
              error={showError(newMaterial?.price)}
              InputLabelProps={{
                shrink: newMaterial?.price !== '',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <TextField
              disabled={isDisabled()}
              label="Número control"
              type="text"
              placeholder="Escribir número de control aquí"
              fullWidth
              name="control_number"
              onChange={(e) =>
                handleOnChange({
                  ...newMaterial,
                  control_number: e.target.value,
                })
              }
              value={newMaterial?.control_number}
              onKeyDown={KeyHandles().keyDownOnlyNumberHandle}
              error={showError(newMaterial?.control_number)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.5}>
            <TextField
              disabled={isDisabled()}
              label="Notas"
              type="text"
              placeholder="Escribir notas aquí"
              fullWidth
              name="notes"
              onChange={(e) =>
                handleOnChange({ ...newMaterial, notes: e.target.value })
              }
              value={newMaterial?.notes}
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1}>
            <Button
              disabled={valid}
              style={{ height: '100%' }}
              variant="contained"
              fullWidth
              onClick={onSubmit}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
