import React, { useEffect, useState } from "react";
import { Table } from "shared/components/Table/Table";
import { ResponsePaginator } from "app/types/common/ResponsePaginator";

import { UseFetch } from "shared/hooks/UseFetch";
import withReactContent from "sweetalert2-react-content";
import { columnsTravels } from "../util/columnsTravels";
import { ModalAdd } from "app/pages/collections/ModalAdd/ModalAdd";
import Swal from "sweetalert2";
import { number } from "@amcharts/amcharts4/core";

const getFilters = (filter: any, pageSize = 10, timeFilter: string, timeInHoursFilter: string) => {
    let filtersString = `page_size=${pageSize}&status=${4}`;
    if (filter.textFilter !== '') {
        filtersString += "&name=" + filter.textFilter;
    }

    if (timeFilter !== '' && timeInHoursFilter !== '') {
        let isLessTime = timeFilter !== 'mayor' ? true : false;
        filtersString += "&is_less_time=" + isLessTime.toString();
        filtersString += "&time=" + timeInHoursFilter;
    }

    return filtersString;
};

export const TavelsTab = ({ textFilter, refresh, setRefresh, timeFilter, timeInHoursFilter }: any) => {
    const [open, setOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [trailers, setTrailers] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const fetchListClients = UseFetch("clients/?page_size=0");
    const fetchListDrivers = UseFetch("drivers/?page_size=0");
    const fetchListTrailers = UseFetch("trailers/?page_size=0");
    const fetchVehicles = UseFetch("vehicles/?page_size=0");
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [idEdit, setIdEdit] = useState(0);
    const [refreshList, setRefreshList] = useState([]);


    useEffect(() => {
        const load = async () => {
            initClients();
            initDrivers();
            initTrailers();
            initVehicles();
        };
        load();
    }, []);


    const initVehicles = () => {
        fetchVehicles.getFetch().then((response) => {
            setVehicles(response.data);
        });
    };

    const initClients = () => {
        return new Promise((resolve) => {
            fetchListClients.getFetch().then((response: any) => {
                setClients(response.data);
                resolve(true);
            });
        });
    };

    const initDrivers = () => {
        return new Promise((resolve) => {
            fetchListDrivers.getFetch().then((response: any) => {
                setDrivers(response.data);
                resolve(true);
            });
        });
    };

    const initTrailers = () => {
        return new Promise((resolve) => {
            fetchListTrailers.getFetch().then((response: any) => {
                setTrailers(response.data);
                resolve(true);
            });
        });
    };

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const urlController = "collections";

    const { getFetch } = UseFetch(`${urlController}?${getFilters({ textFilter }, rowsPerPage, timeFilter, timeInHoursFilter)}`);
    const [data, setData] = useState([] as any[]);



    useEffect(() => {
        setRefresh(Math.random())
    }, [currentPage])

    useEffect(() => {
        getData(`&page=${currentPage + 1}`);
    }, [refresh, rowsPerPage, idEdit]);

    const getData = (queryParams?: string) => {
        getFetch(null, queryParams).then((response: ResponsePaginator) => {
            setCount(response.data.total_items)
            setData(response.data.results);
        });
    };

    const editMethod = (id: any) => {
        setIdEdit(id);
        setOpen(true);
    }

    const fetchDeleteCollection = UseFetch("collections/detail/", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    });

    const deleteMethod = (id: string) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: "Confirmación eliminación del viaje",
            icon: "question",
            text: `¿Estás seguro de eliminar la entrada?`,
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            confirmButtonColor: "#b6f59d",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                fetchDeleteCollection.getFetch(null, id).then((response) => {
                    if (response) {
                        setRefresh(Math.random());
                    }



                });
            }
        });
    };
    return (
        <>
            <ModalAdd
                setOpen={setOpen}
                setRefreshList={setRefresh}
                clients={clients}
                trailers={trailers}
                drivers={drivers}
                vehicles={vehicles}
                open={open}
                idEdit={idEdit}
                setIdEdit={setIdEdit}
            />

            <Table

                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                columns={columnsTravels}
                count={count}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setEditing={editMethod}
                deleteMethod={deleteMethod}//Issac mueve aqui.

            />
        </>
    );
};
