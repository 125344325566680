import React from "react";
import { Typography, Grid, TextField, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export const ModalLayout = ({ children, title = "", width, handleClose }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        padding: 4,
      }}
    >
      <Grid
        item
        className="box-shadow"
        style={{ width: width }}
        xs={3}
        sx={{
          backgroundColor: "white",
          padding: 3,
          borderRadius: 2,
          minHeight: "90vh",
          width: "80vw!important",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ mb: 1 }}>
            {title}
          </Typography>
          <ClearIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>

        {/* {Childrens} */}
        {children}
      </Grid>
    </Grid>
  );
};
