import { useEffect, useMemo } from 'react';
import { useState } from 'react';

export const useForm = (initialForm: any = {}, formValidation: any = {}) => {
    const [formState, setFormState] = useState(initialForm);
    const [formValidations, setFormValidations] = useState({})

    useEffect(() => {

        createVlidations();

    }, [formState])

    const isFormValid = useMemo(() => {

        for (const formValue of Object.keys(formValidation)) {
            if (formValidation[formValue] !== null) return false;


        }
        return true

    }, [formValidations])



    const onInputChange = ({ target }: any) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: value
        });
    }

    const onCheckChange = ({ target }: any) => {
        const { name, checked } = target;
        setFormState({
            ...formState,
            [name]: checked
        });
    }



    const onResetForm = () => {
        setFormState(initialForm);
    }

    const createVlidations = () => {

        const formCheckedValues: any = {};
        for (const formField of Object.keys(formValidation)) {
            const [fn, errorMessage = 'Este campo es requerido!'] = formValidation[formField]
            formCheckedValues[`${formField}Valid`] = fn(formState[formField]) ? null : errorMessage
        }
        setFormValidations(formCheckedValues);



    }

    const returnObj: any = {
        ...formState,
        formState,
        onInputChange,
        onResetForm,
        ...formValidations,
        isFormValid,
        setFormState,
        onCheckChange
    }
    return returnObj;
}