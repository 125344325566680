import {
  List,
  ListItemText,
  IconButton,
  Paper,
  Grid,
  TextField
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const TodoList = ({ todoItems, onRemove, setMaterials, materialsPref, units, index }) => {
  const setNewMaterial = (item, index) => {
    todoItems[index] = item;
    setMaterials([...todoItems]);
  }

  return (
    <div>
      <Paper>
        <List style={{ overflowY: "scroll", height: '45vh' }}>
          {todoItems.map((todoItem, index) => (
            <Grid container spacing={1} key={todoItem.id} style={{ padding: '10px' }}>
              <Grid item xs={12} >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Selecciona material</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="material_id"
                    placeholder="Escribir material"
                    onChange={e => setNewMaterial({ ...todoItem, material_id: e.target.value }, index)}
                    value={todoItem.material_id}
                  >
                    {materialsPref.map((material) => (
                      <MenuItem value={material.id}>{material.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} >
                <TextField
                  label="Precio"
                  type="text"
                  placeholder=""
                  fullWidth
                  name="price"
                  onChange={e => setNewMaterial({ ...todoItem, price: e.target.value }, index)}
                  value={todoItem.price}
                  error={false}
                  helperText={false}
                />
              </Grid>
              <Grid item xs={4} >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Selecciona una unidad de medida</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="unit"
                    placeholder="Escribir unidad de medida"
                    onChange={e => setNewMaterial({ ...todoItem, unit_of_measure: e.target.value }, index)}
                    value={todoItem.unit_of_measure}
                  >
                    {units.map((unit) => (
                      <MenuItem value={unit.id}>{unit.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} >
                <IconButton onClick={() => onRemove(todoItem.id)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </List>
      </Paper>
    </div>
  );
};
