import React, { useEffect, useState } from "react";
import { Table } from "shared/components/Table/Table";
import { ResponsePaginator } from "app/types/common/ResponsePaginator";
import { UseFetch } from "shared/hooks/UseFetch";
import { columnsTravelsTime } from "../util/columnsTravelsTime";





export const TravelsTabTime = ({ textFilter, refresh, setRefresh, timeFilter, timeInHoursFilter }: any) => {

    const getFilters = (filter: any, pageSize = 10, timeFilter: string, timeInHoursFilter: string, sortFilter: boolean) => {
        let filtersString = `page_size=${pageSize}&status=${3}`;
        if (filter.textFilter !== '') {
            filtersString += "&name=" + filter.textFilter;
        }

        if (timeFilter !== '') {
            filtersString += "&order_by_time=" + timeFilter;
        }

        return filtersString;
    };
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const urlController = "collections/average-time";
    const [sortFilter, setSortFilter] = useState(true);

    const { getFetch } = UseFetch(`${urlController}?${getFilters({ textFilter }, rowsPerPage, timeFilter, timeInHoursFilter, sortFilter)}`);
    const [data, setData] = useState([] as any[]);

    const [currentPage, setCurrentPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setRefresh(Math.random())
    }, [currentPage])

    const getData = (queryParams?: string) => {
        getFetch(null, queryParams).then((response: ResponsePaginator) => {
            setCount(response.data.total_items)
            setData(response.data.results);
        });
    };

    useEffect(() => {
        getData(`&page=${currentPage + 1}`);
    }, [refresh, rowsPerPage, timeFilter]);




    return (
        <>
            <h2>Promedios de tiempo</h2>
            <Table
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                columns={columnsTravelsTime}
                count={count}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setEditing={() => { }}
            />
        </>
    );
};
