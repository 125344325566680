import React from 'react';

import { Button } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { CheckCircle } from '@mui/icons-material/';
import {

  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton
} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { red } from '@mui/material/colors';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});
export default function TableContent(props) {
  const { columns, rows, page, setCurrentPage } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: "#D9D9D9" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell
                        align={column.align}>

                        {column.format && typeof value === 'number' ? column.format(value) : value}

                        {(column.id == 'active' && row.active) &&
                          <IconButton><CheckCircle color="success" /></IconButton>
                        }

                        {(column.id == 'active' && !row.active) &&
                          <IconButton><HighlightOffIcon style={{ color: red[500] }} /></IconButton>
                        }
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage='Cantidad de registros por página'
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
