import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertDialog = ({open, handleClose, content, type}) => {
    const [state, setState] = React.useState({
        vertical: 'bottom',
        horizontal: 'right',
    });
    const { vertical, horizontal } = state;
    return (
        //types: success, info, danger, warning
        <Snackbar 
            open={open} 
            autoHideDuration={5000} 
            anchorOrigin={{vertical, horizontal}}
            onClose={handleClose}
        >
            <Alert 
                onClose={handleClose} 
                severity={type} 
                sx={{ width: '100%' }}
                
                >
                    {content}
            </Alert>
        </Snackbar>
    );
}