import { useState, CSSProperties, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import MoonLoader from "react-spinners/MoonLoader";

const override = {
  width: "100%",
  height: "100vh",
  position: "absolute",
  zIndex: "200",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#b3b3b32e",
  top: 0,
  left: 0,
};

export const Spinner = ({ loading }) => {
  let [color, setColor] = useState("#ffffff");
  let [style, setStyle] = useState(override);
  let [key, setKey] = useState(Math.random());

  useEffect(() => {
    let newStyle = { ...style };
    newStyle.display = loading ? "flex" : "none";
    setStyle(newStyle);
    setKey(Math.random());
  }, [loading]);

  return (
    <div className="sweet-loading" style={style} key={key}>
      <div>
        <MoonLoader
          color="rgb(109, 136, 166)"
          loading={loading}
          size={150}
          aria-label="Cargando..."
          data-testid="loader"
        />
      </div>
      <div>Cargando...</div>
    </div>
  );
};
