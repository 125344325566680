import React from 'react'
import { TableContent } from './TableContent';
import { TableProps } from './Table.type';

export const Table = (props: TableProps) => {
    if (!props.data) {
        return <div>Cargando...</div>;
    }

    return (
        <>
            <TableContent
                rowsPerPage={props.rowsPerPage}
                setRowsPerPage={props.setRowsPerPage}
                count={props.count}
                columns={props.columns}
                rows={props.data}
                page={props.currentPage}
                setCurrentPage={props.setCurrentPage}
                setEditing={props.setEditing}
                deleteMethod={props.deleteMethod}
            />
        </>
    )
}

