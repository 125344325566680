import { useState, useEffect } from "react";
import { Button, Modal, Fade, Grid, TextField } from "@mui/material";

import { AlertDialog } from "../../../../shared/components/AlertDialog";
import { ModalLayout } from "../../../../shared/components/ModalLayout";
import { UseFetch } from "../../../../shared/hooks/UseFetch";
import { TabPanel } from "../../../../shared/components/TabPanel";

export const ModalVehicles = (props: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [nameVehicle, setNameVehicle] = useState("");

  const fetchAddVehicle = UseFetch("vehicles/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const fetchUpdateVehicles = UseFetch(`vehicles/${props.vehiclesEditing}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const fetchListClients = UseFetch("vehicles/");

  const handleOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    closeView();
    props.setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (props.vehiclesEditing) {
      searchVehicleById(props.vehiclesEditing);
      props.setOpen(true);
    }
  }, [props.vehiclesEditing]);

  const fetchVehiclesById = UseFetch("vehicles/" + props.vehiclesEditing + "/");

  const searchVehicleById = (id: any) => {
    fetchVehiclesById.getFetch().then((response: any) => {
      if (response.data) {
        const { data } = response;

        setNameVehicle(data.name);
      }
    });
  };

  const isValid = () => {
    return nameVehicle != "";
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (props.vehiclesEditing) {
      fetchUpdateVehicles.getFetch({ name: nameVehicle }).then(
        () => {
          setTypeAlert("success");
          setContent(`Se ha editado con éxito.`);
          setOpenDialog(true);
          closeView();
        },
        (error) => {
          setTypeAlert("error");
          setContent(`Se ha producido un error al editar.`);
          setOpenDialog(true);
          closeView();
        }
      );
    } else {
      await fetchAddVehicle.getFetch({ name: nameVehicle }).then(
        () => {
          setTypeAlert("success");
          setContent(`Se ha guardado con éxito.`);
          setOpenDialog(true);
          closeView();
        },
        (error) => {
          setTypeAlert("error");
          setContent(`Se ha producido un error al guardar.`);
          setOpenDialog(true);
          closeView();
        }
      );
    }
  };

  const closeView = () => {
    onResetForm();
    props.setRefresh(Math.random())
    props.setOpen(false);
  };

  const onResetForm = () => {
    props.setVehiclesEditing(0);
    setNameVehicle("");
  };

  return (
    <>
      <AlertDialog
        type={typeAlert}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <Modal open={props.open} onClose={handleClose} closeAfterTransition>
        <Fade in={props.open}>
          <div className="modal-container">
            <ModalLayout
              title={
                props.vehiclesEditing
                  ? "Editar de vehículos"
                  : "Alta de vehículos"
              }
              width={"60%"}
              handleClose={handleClose}
            >
              <TabPanel>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={2} sx={{ minHeight: 100 }}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <TextField
                        label="Nombre del vehículo"
                        type="text"
                        placeholder=""
                        fullWidth
                        name="name"
                        value={nameVehicle}
                        onChange={(e) => setNameVehicle(e.target.value)}
                        error={false}
                        helperText={false}
                        inputProps={{ maxLength: 50 }}

                      />
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>

              <Grid
                container
                spacing={2}
                sx={{ mb: 2, mt: 1 }}
                style={{ bottom: "5%" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: '#afafaf', color: 'white' }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!isValid()}
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </ModalLayout>
          </div>
        </Fade>
      </Modal >
    </>
  );
};
