import React from 'react'

export const ErrorPage = () => {
  return (
    <>
    <h1>Error</h1>
    <hr />
    </>
  )
}
