import React from "react";
import { makeStyles } from "tss-react/mui";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";

const useStyles = makeStyles()({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function PurchasesContent({
  columns,
  rows,
  editMethod,
  deleteMethod,
  filtersActive,
  setFiltersActive,
  setPageSize,
  pageSize,
  count,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
    setPage(0); // Reset the current page to the first page when changing the number of rows per page
  };

  const handleOnClickHeader = (id) => {
    let filters = [...filtersActive];
    if (filters.includes(id)) {
      filters.splice(filters.indexOf(id), 1);
    } else {
      filters.push(id);
    }
    setFiltersActive(filters);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#D9D9D9",
                    boxShadow: filtersActive.includes(column.id)
                      ? "rgb(109, 136, 166) 3px 3px 6px 0px inset, rgba(109, 136, 166, 0.5) -3px -3px 6px 1px inset"
                      : "",
                    cursor: "pointer",
                  }}
                  onClick={() => handleOnClickHeader(column.id)}
                >
                  <div style={{ display: "flex", placeContent: "center" }}>
                    <div>{column.label}</div>
                    <div
                      style={{
                        alignSelf: "center",
                        display: filtersActive.includes(column.id)
                          ? ""
                          : "none",
                      }}
                    >
                      <DeleteForever style={{ color: "gray" }} />
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={`${row.id}${column.id}`} align="center">
                          {column.format
                            ? column.format(value)
                            : column.render
                              ? (column.label == "Modificar" &&
                                column.render(editMethod, row)) ||
                              (column.label == "Eliminar" &&
                                column.render(deleteMethod, row.id))
                              : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage='Cantidad de registros por página'
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} // This will update the rowsPerPage state
      />
    </Paper>
  );
}
