import React, { useEffect, useState } from "react";
import Filters from "./Filters/Filters";
import { UseFetch } from "../../../shared/hooks/UseFetch";

import { ResponsePaginator } from "app/types/common/ResponsePaginator";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from "shared/components/TabPanel";
import { TavelsTab } from "./Tabs/TavelsTab";
import { TravelsTabTime } from "./Tabs/TravelsTabTime";

export const Travels = () => {

  const [textFilter, setTextFilter] = useState('');
  const [timeFilter, setTimeFilter] = useState('asc');
  const [timeInHoursFilter, setTimeInHoursFilter] = useState('');
  const [tab, setTab] = useState(0);

  const [refresh, setRefresh] = useState(Infinity);


  const handleChangeTab = (event: any, newValue: any) => {
    setTab(newValue);
  };


  return (
    <>
      <h1>Viajes</h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Filters setTextFilter={setTextFilter} setRefresh={setRefresh} tabActive={tab}
          setTimeFilter={setTimeFilter}
          setTimeInHoursFilter={setTimeInHoursFilter}

        />

      </div>
      <hr />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Viajes" />
          <Tab label="Tiempo promedio por chofer" />
        </Tabs>
      </Box>

      <TabPanel value={tab} index={0}>
        <TavelsTab textFilter={textFilter} refresh={refresh} setRefresh={setRefresh} timeFilter={timeFilter}
          timeInHoursFilter={timeInHoursFilter} />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <TravelsTabTime textFilter={textFilter} refresh={refresh} setRefresh={setRefresh} timeFilter={timeFilter}
          timeInHoursFilter={timeInHoursFilter} />
      </TabPanel>
    </>
  );
};
