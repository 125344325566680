import React from "react";
import TableContent from "./TableContent";

export const ClientsTable = (props) => {
  const convertMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };
  
  const columns = [
    {
      id: "company_name",
      label: "Proveedor",
      minWidth: 170,
      align: "center",
    },
    {
      id: "short_name",
      label: "Nombre corto",
      minWidth: 100,
      align: "center",
    },
    {
      id: "collection_time_limit",
      label: "Tiempo estimado de recolección",
      minWidth: 100,
      align: "center",
      format: (value) => convertMinutesToTime(value), // Use the formatter here
    },
    {
      id: "active",
      label: "Activo",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "modify",
      label: "Modificar",
      minWidth: 170,
      align: "right",
    },
  ];

  if (!props.data) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <TableContent
        rowsPerPage={props.rowsPerPage}
        setRowsPerPage={props.setRowsPerPage}
        columns={columns}
        rows={props.data}
        count={props.count}
        page={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        setClientEditing={props.setClientEditing}
      />
    </>
  );
};
