import { useState } from "react";
import { UserContext } from "../context/UserContext";

// const User = {
//     id: 123,
//     name: 'Isaac vargas',
//     email: 'isaac@hotmail.com'
// }


export const UserProvider = ({ children }) => {
    const [user, setUser] = useState();
    return (
        // <UserContext.Provider value={ { Hi: 'world', user: User } }>
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    )
}