import React, { useEffect, useState } from "react";
import { API_URL } from "../../constants";
import { useNavigate } from "react-router-dom";

export const UseFetch = (url: string, options: any = {}) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    data: null,
    isLoading: false,
    hasError: null,
  });

  const getFetch = async (
    formState?: any,
    id: undefined | string = undefined
  ): Promise<any> => {
    if (formState) {
      options.body = JSON.stringify(formState);
    }

    setState({
      ...state,
      isLoading: true,
    });
    try {
      let token = localStorage.getItem("Token");
      let newOptions = { ...options };
      if (!url.includes("login")) {
        if (!validateToken()) {
          localStorage.clear();
          window.location.href = window.location.origin + "/login";
          return;
        }
      }
      if (token) {
        console.log("token", token);

        let headers: any = {};
        if (newOptions.headers) {
          headers = { ...newOptions.headers };
        }
        headers.Authorization = "Bearer " + token;
        newOptions.headers = headers;
      }

      const resp = await fetch(`${API_URL}/${url}${id ? id : ""}`, newOptions);
      const data = await resp.json();
      const response = {
        data: data,
        isLoading: false,
        hasError: null,
      };
      setState(response);
      return response;
    } catch (error: any) {}
  };

  const validateToken = () => {
    // Obtener el token del localStorage
    let token = localStorage.getItem("Token");

    if (token) {
      const parts = token.split(".");

      if (parts.length !== 3) {
        return false;
      }

      const payload = JSON.parse(atob(parts[1]));

      const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
      if (payload.exp && payload.exp < currentTime) {
        return false;
      }

      let newOptions = { ...options };
      let headers: any = {};
      if (newOptions.headers) {
        headers = { ...newOptions.headers };
      }
      headers.Authorization = "Bearer " + token;
      newOptions.headers = headers;
      return true;
    } else {
      return false;
    }
  };

  return {
    data: state.data,
    isLoading: state.isLoading,
    hasError: state.hasError,
    state,
    getFetch,
  };
};
