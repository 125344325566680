import React, { useState, useEffect } from "react";
import TableContent from "./TableContent";
import { Grid, Typography, Paper } from "@mui/material";

export const StaticsTable = ({ data, mostrarTotal }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [columns, setColumns] = useState([
    {
      id: "material",
      label: "Material",
      minWidth: 170,
      align: "center",
    },
    {
      id: "total",
      label: "Total",
      minWidth: 100,
      align: "center",
      format: (val) => val.toLocaleString(),
    },
  ]);

  useEffect(() => {
    if (mostrarTotal) {
      setColumns([
        {
          id: "material",
          label: "Material",
          minWidth: 170,
          align: "center",
        },
        {
          id: "total",
          label: "Cantidades totales",
          minWidth: 100,
          align: "center",
          format: (val) => val.toLocaleString(),
        },
        {
          id: "total_price",
          label: "Total",
          minWidth: 100,
          align: "center",
          format: (val) => "$" + val.toLocaleString(),
        },
        {
          id: "avg_cost_total",
          label: "Costo Promedio (total precio/cantidades totales)",
          minWidth: 100,
          align: "center",
          format: (val) => "$" + val.toLocaleString(),
        },
      ]);
      return;
    }
    setColumns([
      {
        id: "material",
        label: "Material",
        minWidth: 170,
        align: "center",
      },
      {
        id: "total",
        label: "Total",
        minWidth: 100,
        align: "center",
        format: (val) => val.toLocaleString(),
      },
    ]);
  }, [mostrarTotal]);

  // Calcular la suma total de total_price
  const totalSum = data.reduce((acc, curr) => acc + curr.total_price, 0);

  if (!data) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <TableContent
        columns={columns}
        rows={data}
        page={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {mostrarTotal && (
        <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: "10px", backgroundColor: "#f5f5f5" }}>
              <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
                Total: ${totalSum.toLocaleString()}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};
