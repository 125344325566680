import React from 'react'

import { Menu } from './Menu';
import AppTheme from "styles/theme/AppTheme";
export const HomePage = () => {


  return (
    <>
      <AppTheme>
        <h1>Altas</h1>

        <Menu />

      </AppTheme>
    </>
  )
}
