import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { API_URL } from '../../constants';

export const downloadFile = async (url) => {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem('Token')
    try {
      const response = await axios.get(`${API_URL}/${url}`, {
        responseType: 'blob', // The response type indicates that it is a file
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to Authorization header
        },
      });

      // Create a URL object for the downloaded file
      const blob_url = window.URL.createObjectURL(new Blob([response.data]));

      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const year = String(currentDate.getFullYear());
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');
      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

      const filename = "reporte de entradas " + formattedDate + ".xlsx"
      // Create a temporary link to download the file
      const link = document.createElement('a');
      link.href = blob_url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      resolve({ success: true });
    } catch (error) {

      reject({ success: false, error });

    }
  });
};