import { Column } from 'shared/components/Table/Table.type';

export const columnsTravelsTime: Column[] = [
    {
        id: "total",
        label: "Cantidad",
        minWidth: 170,
        align: "center"
    },
    {
        id: "client_short_name",
        label: "Proveedor",
        minWidth: 170,
        align: "center",
    },
    {
        id: "driver_name",
        label: "Chofer",
        minWidth: 170,
        align: "center",
    },
    {
        id: "average_duration",
        label: "Tiempo promedio",
        minWidth: 170,
        align: "center",
    },
];