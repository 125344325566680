import { useState } from "react";
import { TextField, Button, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";

import { KeyHandles } from "shared/handlers/KeyHandles";

export const TodoForm = ({
  initMaterials,
  onSubmit,
  materials,
  setMaterials,
  materialsPref,
  units,
}) => {
  const [newMaterial, setNewMaterial] = useState({
    name: "",
    price: 0,
    id: Date.now(),
  });

  const [keyAutomComplete, setKeyAutocomplete] = useState(Math.random());
  const [keyMaterial, setKeyMaterial] = useState(Math.random());
  const handleAddTodo = (event) => {
    event.preventDefault();
    setMaterials((prevMaterials) => [...prevMaterials, newMaterial]);
    onSubmit([...materials, newMaterial]);
    setNewMaterial({
      name: "",
      price: 0,
      id: Date.now(),
      material_id: undefined,
      unit_of_measure: 0
    });
    setKeyAutocomplete(Date.now())
    setKeyMaterial(Date.now())
    return;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setNewMaterial({ ...newMaterial, name: e.target.value });
    setNewMaterial({ ...newMaterial, price: e.target.value });
    setMaterials(value);
  };

  const isDisable = () => {
    if (
      newMaterial.material_id &&
      newMaterial.unit_of_measure &&
      newMaterial.price >= 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <form onSubmit={(e) => handleAddTodo(e)}>
      <h5>Materiales</h5>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            style={{ width: "100%" }}
            disablePortal
            disabled={false}
            key={keyAutomComplete}
            id="combo-box-demo"
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              if (value) {
                setNewMaterial({ ...newMaterial, material_id: value.id });
              }
            }}
            options={materialsPref}
            value={newMaterial.material_id}
            renderInput={(params) => (
              <TextField
                style={{ width: "100%" }}
                {...params}
                label="Selecciona un material:"
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            onKeyUp={KeyHandles().keyDownOnlyNumberHandle}
            type="number"
            onChange={(e) =>
              setNewMaterial({ ...newMaterial, price: e.target.value < 0 ? 0 : e.target.value, })
            }
            value={newMaterial.price}
            name="priceMaterial"
            label="Precio"
            fullWidth
          />
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Selecciona una unidad de medida
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="unit"
              key={keyMaterial}
              placeholder="Escribir unidad de medida"
              onChange={(e) =>
                setNewMaterial({
                  ...newMaterial,
                  unit_of_measure: e.target.value,
                })
              }
              value={newMaterial.unit_of_measure}
            >
              {units.map((unit) => (
                <MenuItem value={unit.id}>{unit.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={handleAddTodo}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isDisable()}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
