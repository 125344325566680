import React from 'react'
import { Typography, Grid, TextField, Button } from '@mui/material'

export const AuthLayout = ({children, title='' }) => {
  return (
    <Grid
    container
    spacing={ 0 }
    direction= "column"
    alignItems= "center"
    justifyContent="center"
    sx={{ minHeight: '100vh', backgroundColor: '#444b55ed', padding: 4 }}
  >

        <Grid item 
        className='box-shadow'
        xs={ 3 }
        sx={{ backgroundColor: 'white', padding: 3, borderRadius:15 }} 
        >
            <Typography variant='h5' sx={{mb: 1}} >{title}</Typography>
            {children}
            
        </Grid>
    </Grid>
  )
}
