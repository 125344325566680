import React, { useState, useEffect } from "react";
import "./DateRange.css"
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TextField, Button } from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import locale from 'date-fns/locale/es';

const dictCalendarLabels = {
    'Today': 'Hoy',
    'Yesterday': 'Ayer',
    'This Week': 'Esta semana',
    'Last Week': 'Semana anterior',
    'This Month': 'Este mes',
    'Last Month': 'Mes anterior',
}

export const DateRange = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setCalendarLabels();
    }, [isOpen])

    const setCalendarLabels = () => {
        if (document.getElementsByClassName('rdrStaticRangeLabel').length > 0) {
            for (let index = 0; index < document.getElementsByClassName('rdrStaticRangeLabel').length; index++) {
                const element = document.getElementsByClassName('rdrStaticRangeLabel')[index];
                if (dictCalendarLabels[element.innerHTML]) {
                    element.innerHTML = dictCalendarLabels[element.innerHTML]
                }
            }
        }
        if (document.getElementsByClassName('rdrInputRanges').length > 0) {
            for (let index = 0; index < document.getElementsByClassName('rdrInputRanges').length; index++) {
                const element = document.getElementsByClassName('rdrInputRanges')[index];
                element.innerHTML = '';
            }
        }
    }


    const handleDateChange = (ranges) => {

        props.setDateRange(ranges.selection);

        if (ranges.selection.endDate && ranges.selection.endDate != ranges.selection.startDate) {
            setIsOpen(!isOpen);
        }

        if (props.handleDateChange) {
            props.handleDateChange();
        }
    };

    const onToggle = () => {
        setIsOpen(!isOpen)
    };

    const getValueOfDate = () => {

        let value = ''

        if (!props.dateRange?.endDate) {
            value = 'Seleccione rango de fecha';
        } else {

            if (props.dateRange) {
                value = `Desde: ${props.dateRange?.startDate?.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                })}`
                if (props.dateRange?.endDate) {
                    value += `
                Hasta: ${props.dateRange?.endDate?.toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    })}`;
                }
            }
        }

        return value;
    }



    return (
        <div>
            <div style={{ display: 'flex', margin: 0, height: '100%' }}>
                <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyUp={(e) => e.preventDefault()}
                    onClick={onToggle}
                    style={{ width: "100%" }}
                    label="Buscar fecha"
                    value={getValueOfDate()}
                />

            </div>
            <div style={{ width: '780px !important' }}>

                {
                    isOpen && (
                        <DateRangePicker
                            ranges={[props.dateRange]}
                            onChange={handleDateChange}
                            className="DateRangePicker"
                            locale={locale}
                            showDateDisplay={false}
                        />
                    )
                }
            </div>
        </div >
    );
}
