import React from 'react'
import { CheckCircle, Edit, DeleteForever } from '@mui/icons-material/';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    Button,

} from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { red } from '@mui/material/colors';
import { Column } from './Table.type';


export const TableContent = (props: any) => {
    const { columns, rows, page, setCurrentPage } = props;

    const useStyles: any = makeStyles()({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });

    const classes = useStyles();

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.setRowsPerPage(+event.target.value);
        setCurrentPage(0);
    };

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column: Column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: "#D9D9D9" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map((column: Column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={row.id + column.id}
                                                align={column.align}>

                                                {column.format && typeof value === 'number' ? column.format(value) :
                                                    column.render ? column.render(row) : value}

                                                {(column.label === 'Modificar') ? (<Button variant="contained" style={{ backgroundColor: '#6D88A6', color: 'white' }} onClick={() => props.setEditing(row.id)}><Edit /></Button>) : ''}
                                                {column.label === "Eliminar" && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => props.deleteMethod(row.id)}
                                                    >
                                                        <DeleteForever style={{ color: "white" }} />
                                                    </Button>
                                                )}
                                                {(column.id === 'active' && row.active) &&
                                                    <IconButton><CheckCircle color="success" /></IconButton>
                                                }

                                                {(column.id === 'active' && !row.active) &&
                                                    <IconButton><HighlightOffIcon style={{ color: red[500] }} /></IconButton>
                                                }
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage='Cantidad de registros por página'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

