import React, { useState, useEffect } from "react";
import { PurchaseTable } from "./TablePurchases/PurchaseTable";
import { ModalPurchase } from "./ModalPurchases/ModalPurchase";
import { PurchaseHeader } from "./PurchaseHeader/PurchaseHeader";
import "./purchase.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Spinner } from "shared/components/Spinner/Spinner";
import { UseFetch } from "shared/hooks/UseFetch";
import jwtDecode from "jwt-decode";
import { useParams } from 'react-router-dom';

const getRole = () => {
  const token = localStorage.getItem("Token");
  const decodedToken = jwtDecode(token);
  return decodedToken.role;
};

export const Purchase = () => {
  const { openModal, id, idProveedor, idVehicle, idDriver, idTrailer } = useParams();

  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    clientId: -1,
  });
  const [role, setRole] = useState([]);
  const [data, setData] = useState([]);
  const [refreshList, setRefreshList] = useState([]);
  const [open, setOpen] = useState(false);
  const [purchase, setPurchase] = useState(null);
  const [filtersActive, setFiltersActive] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [clients, setClients] = useState([]);
  const fetchListClients = UseFetch("clients/?page_size=0");
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [options, setOption] = useState([
    { value: "client_short_name", label: "Proveedor" },
    { value: "formated_date", label: "Fecha" },
    { value: "outbound_reference_number", label: "Folio salida" },
    { value: "ticket", label: "Folio ticket" },
    { value: "control_number", label: "Número control" },
    { value: "material_name", label: "Material", },
    { value: "weight", label: "Cantidad", },
    { value: "price", label: "Precio", },
    { value: "vehicle_name", label: "Vehículo", },
    { value: "trailer_name", label: "Remolque", },
    { value: "notes", label: "Notas", },
    { value: "edit", label: "Modificar", },
    { value: "delete", label: "Eliminar", },
  ]);
  const [selectedOptions, setSelectedOptions] = useState([
    { value: "client_short_name", label: "Proveedor" },
    { value: "formated_date", label: "Fecha" },
    { value: "outbound_reference_number", label: "Folio salida" },
    { value: "ticket", label: "Folio ticket" },
    { value: "control_number", label: "Número control" },
    { value: "material_name", label: "Material", },
    { value: "weight", label: "Cantidad", },
    { value: "price", label: "Precio", },
    { value: "vehicle_name", label: "Vehículo", },
    { value: "trailer_name", label: "Remolque", },
    { value: "notes", label: "Notas", },
    { value: "edit", label: "Modificar", },
    // { value: "delete", label: "Eliminar", },
  ]);

  const handleChange = selectedOptions => {
    setSelectedOptions(selectedOptions);
  };

  const fetchDeletePurchase = UseFetch("purchases/", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openModal) {
      setOpen(true);
    }
  }, [])

  const editMethod = (row) => {
    setPurchase(row);
    setOpen(true);
  };

  const deleteMethod = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Confirmación eliminación de entrada",
      icon: "question",
      text: `¿Estás seguro de eliminar la entrada?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#b6f59d",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeletePurchase.getFetch(null, id).then((response) => {
          setRefreshList(Math.random());
        });
      }
    });
  };

  const initClients = () => {
    return new Promise((resolve) => {
      fetchListClients.getFetch().then((response) => {
        setClients(response.data);
        resolve();
      });
    });
  };

  useEffect(() => {
    const load = async () => {
      initClients();
      setRole(getRole());
    };
    load();
  }, []);

  return (
    <>
      <Spinner loading={loading} />
      <ModalPurchase
        setRefreshList={setRefreshList}
        setOpen={setOpen}
        open={open}
        purchase={purchase}
        setPurchase={setPurchase}
        clients={clients}
        setLoading={setLoading}
        role={role}
        preSelectedIdProveedor={idProveedor}
        preSelectedIdDriver={idDriver}
        preSelectedIdTrailer={idTrailer}
        preSelectedIdVehicle={idVehicle}
        travelId={id}
      />
      <h1>Entradas</h1>
      <PurchaseHeader
        setOpen={setOpen}
        setFilter={setFilter}
        filters={filter}
        data={data}
        filtersActive={filtersActive}
        setRefreshList={setRefreshList}
        selectedOptions={selectedOptions}
        handleChange={handleChange}
        options={options}
      />
      <hr />
      <PurchaseTable
        setFiltersActive={setFiltersActive}
        filtersActive={filtersActive}
        refreshList={refreshList}
        options={selectedOptions}
        filter={filter}
        data={data}
        count={count}
        setData={setData}
        editMethod={editMethod}
        deleteMethod={deleteMethod}
        setLoading={setLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCount={setCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
