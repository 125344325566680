import { TextField, Grid, Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';

import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

const Filters = ({ setTextFilter, setCheck, check, setRefresh, setOpen }) => {

  const handleOnChangeActive = (event, checked) => {
    setCheck(checked);
    setRefresh(Math.random())
  }

  const handleOnChangeText = (event, checked) => {
    setTextFilter(event.target.value)
    setRefresh(Math.random())
  }

  return (
    <>

      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={5}>
          <TextField
            disabled={false}
            label="Buscar"
            type="text"
            placeholder=""
            onChange={handleOnChangeText}
            error={false}
            helperText={false}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5} style={{ float: 'left' }} fullWidth >
          <Tooltip title="Filtrar por activos" placement="top">
            <div style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 10
            }}>
              <InputLabel>Activo</InputLabel>
              <Checkbox checked={check} onChange={handleOnChangeActive} />
            </div>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <Button variant="contained" color="primary"
            onClick={() => setOpen(true)}
            style={{ float: 'right' }} fullWidth
          >
            Agregar choferes
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default Filters