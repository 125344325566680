import React, { useEffect, useState } from 'react'
import { Modal } from './Modal/Modal'
import { Table } from "../../../shared/components/Table/Table";
import { Column } from '../../../shared/components/Table/Table.type';
import { UseFetch } from '../../../shared/hooks/UseFetch';
import Filters from './Filters/Filters';
import { ResponsePaginator } from 'app/types/common/ResponsePaginator';


const getFilters = (filter: any, pageSize = 10) => {
  let filtersString = `page_size=${pageSize}`;
  if (filter.textFilter !== '') {
    filtersString += "&name=" + filter.textFilter;
  }
  return filtersString;
};


export const Materials = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: Column[] = [
    {
      id: "name",
      label: "Nombre",
      minWidth: 170,
      align: "center"
    },
    {
      id: "",
      label: "Modificar",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },

  ];
  const [textFilter, setTextFilter] = useState('');

  const { getFetch } = UseFetch(`materials/?${getFilters({ textFilter }, rowsPerPage)}`);
  const [open, setOpen] = useState(false);

  const [materialsEditing, setMaterialsEditing] = useState(false)
  const [refresh, setRefresh] = useState(Infinity);

  const [MaterialsData, setMaterialsData] = useState([] as any[])
  const [currentPage, setCurrentPage] = useState(0)
  const [count, setCount] = useState(0);


  useEffect(() => {
    setRefresh(Math.random())
  }, [currentPage])

  useEffect(() => {
    getData(`&page=${currentPage + 1}`)
  }, [refresh, rowsPerPage]);

  const getData = (queryParams?: string) => {
    getFetch(null, queryParams).then((response: ResponsePaginator) => {
      setCount(response.data.total_items);
      setMaterialsData(response.data.results);
    });
  };


  return (
    <>
      <h1>Materiales</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} >
        <Filters setTextFilter={setTextFilter} setRefresh={setRefresh} setOpen={setOpen}
        />
        <Modal
          open={open}
          setOpen={setOpen}
          setMaterialsData={setMaterialsData}
          setCurrentPage={setCurrentPage}
          materialsEditing={materialsEditing}
          setMaterialsEditing={setMaterialsEditing}
          setRefresh={setRefresh}
        />
      </div>
      <hr />
      <Table
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        columns={columns}
        count={count}
        data={MaterialsData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setEditing={setMaterialsEditing}
      />
    </>
  )
}