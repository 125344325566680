import React from 'react'
import { ThemeProvider } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { mainTheme } from './mainTheme'


const AppTheme = ({ children }: any) => {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>

  )
}

export default AppTheme