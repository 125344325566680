import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from '@mui/material';

const TimeBasedButton = ({ fechafin, onClickMethod, label, model, secondParamClick, timeLimit, status }) => {
    const [color, setColor] = useState('green');

    useEffect(() => {
        const updateButtonColor = () => {
            const now = moment();
            const endTime = moment(fechafin);
            const differenceInMinutes = now.diff(endTime, 'minutes');
            const lessMidTime = timeLimit / 2;

            if (differenceInMinutes < lessMidTime) {
                setColor('green');
            } else if (differenceInMinutes >= lessMidTime && differenceInMinutes < timeLimit) {
                setColor('orange');
            } else {
                setColor('red');
            }
        };

        // Llama a la función inmediatamente para establecer el color inicial
        updateButtonColor();

        // Configurar un intervalo para actualizar el color cada segundo
        const intervalId = setInterval(updateButtonColor, 1000);

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId);
    }, [fechafin]);

    return (
        <Button variant="contained" disabled={status === 3} className="btn w-100" style={{ backgroundColor: status === 3 ? 'gray' : color, color: color === 'red' || color === 'green' ? 'white' : 'black' }} onClick={() => onClickMethod(model)}>
            {label}
        </Button>
    );
};

export default TimeBasedButton;
