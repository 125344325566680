import React, { useEffect, useState } from "react";
import { Button, Modal, Fade, Grid, TextField } from "@mui/material";
import { AlertDialog } from "shared/components/AlertDialog";
import { ModalLayout } from "shared/components/ModalLayout";


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';

export const ModalVisibleColumn = ({
    setOpen,
    open,
    selectedOptions,
    handleChange,
    options
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [content, setContent] = useState("");
    const [typeAlert, setTypeAlert] = useState("");

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const insertIntoList = (option) => {
        if (!selectedOptions.find((item) => item.value == option.value)) {
            handleChange([...selectedOptions, option])
        } else {
            const newOptions = selectedOptions.filter(item => item.value != option.value);
            handleChange([...newOptions])
        }
    }

    const isOptionChecked = (option) => {
        const value = selectedOptions.find((item) => item.value == option.value);
        return value != null && value != undefined;
    }

    return (
        <>
            <AlertDialog
                type={typeAlert}
                content={content}
                open={openDialog}
                handleClose={handleCloseDialog}
            />
            <Modal open={open} onClose={handleClose} closeAfterTransition style={{ overflowY: 'auto' }}>
                <Fade in={open}>
                    <div className="modal-container" >
                        <ModalLayout
                            width="50vw"
                            style={{ minWidth: "0px!important" }}
                            title={"Configuración de columnas visibles"}
                            handleClose={handleClose}
                        >
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        {options.map((option) => {
                                            const labelId = `checkbox-list-label-${option.value}`;
                                            return (
                                                <ListItem
                                                    key={option.value}
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} onClick={(e) => insertIntoList(option)} dense>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={isOptionChecked(option)}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText id={labelId} primary={`${option.label}`} />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        </ModalLayout>
                    </div>
                </Fade>
            </Modal >
        </>
    )
}