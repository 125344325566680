import React, { useEffect, useState } from "react";
import { Modal } from "./Modal/Modal";
import Filters from "./Filters/Filters";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UseFetch } from "../../../shared/hooks/UseFetch";
import { Table } from "shared/components/Table/Table";
import { columns } from "./util/columns";
import { ResponsePaginator } from "app/types/common/ResponsePaginator";

const getFilters = (filter: any, pageSize = 10) => {
  let filtersString = `page_size=${pageSize}`;
  if (filter.textFilter !== '') {
    filtersString += "&name=" + filter.textFilter;
  }
  return filtersString;
};

export const User = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  const { getFetch } = UseFetch(`users/?${getFilters({ textFilter }, rowsPerPage)}`);

  const [refresh, setRefresh] = useState(Infinity);
  const [count, setCount] = useState(0);
  const [editing, setEditing] = useState(false);

  const [data, setData] = useState([] as any[]);
  const [originalData, setOriginalData] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchDelete = UseFetch(`users/delete/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const onFilterChange = (event: any) => {
    const value = event.target.value;
    const dataFiltered = originalData.filter((item: any) => {
      const fullname = item.first_name + ' ' + item.last_name;
      return fullname.toLowerCase().includes(value.toLowerCase())
    }
    );
    setData(dataFiltered);
    if (!value) {
      getData();
    }
  };

  useEffect(() => {

    setRefresh(Math.random());
  }, [currentPage])

  const getData = (queryParams?: string) => {
    getFetch(null, queryParams).then((response: ResponsePaginator) => {
      setCount(response.data.total_items)
      setData(response.data.results);
      setOriginalData(response.data.results);
    });
  };

  useEffect(() => {
    getData(`&page=${currentPage + 1}`)
  }, [refresh, rowsPerPage]);

  const onDelete = (id: any) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Confirmación",
      icon: "question",
      text: `¿Estás seguro de eliminar el usuario?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#b6f59d",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDelete.getFetch(null, `${id}/`).then(() => {
          setRefresh(Math.random());
        });
      }
    });
  }


  return (
    <>
      <h1>Usuarios</h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Filters
          setTextFilter={setTextFilter}
          setRefresh={setRefresh}
          setOpen={setOpen}
        />
        <Modal
          setRefreshUsers={setRefresh}
          setData={setData}
          setCurrentPage={setCurrentPage}
          editing={editing}
          setEditing={setEditing}
          setOpen={setOpen}
          open={open}
        />
      </div>
      <hr />
      <Table
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        columns={columns}
        count={count}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setEditing={setEditing}
        deleteMethod={onDelete}
      />
    </>
  );
};
