import { useState, useEffect } from "react";
import { Button, Modal as ModalMaterial, Fade, Grid, TextField } from "@mui/material";
import { TabPanel } from "../../../../shared/components/TabPanel";
import { UseFetch } from "../../../../shared/hooks/UseFetch";
import { AlertDialog } from "../../../../shared/components/AlertDialog";
import { ModalLayout } from "../../../../shared/components/ModalLayout";

export const Modal = (props: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [nameMaterial, setNameMaterial] = useState("");

  const fetchAddMaterial = UseFetch("materials/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const fetchUpdateMaterials = UseFetch(
    `materials/${props.materialsEditing}/`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const fetchListClients = UseFetch("materials/?page=1");


  const handleClose = () => {
    closeView();
    props.setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (props.materialsEditing) {
      searchMaterialById(props.materialsEditing);
      props.setOpen(true);
    }
  }, [props.materialsEditing]);

  const fetchMaterialsById = UseFetch(
    "materials/" + props.materialsEditing + "/"
  );

  const searchMaterialById = (id: any) => {
    fetchMaterialsById.getFetch().then((response: any) => {
      if (response.data) {
        const { data } = response;
        setNameMaterial(data.name);
      }
    });
  };

  const isValid = () => {
    return nameMaterial != "";
  };

  const refreshDataMaterials = () => {
    props.setRefresh(Math.random())
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (props.materialsEditing) {
      fetchUpdateMaterials.getFetch({ name: nameMaterial }).then(
        () => {
          setTypeAlert("success");
          setContent(`Se ha editado con éxito.`);
          setOpenDialog(true);
          closeView();
        },
        (error) => {
          setTypeAlert("error");
          setContent(`Se ha producido un error al editar.`);
          setOpenDialog(true);
          closeView();
        }
      );
    } else {
      await fetchAddMaterial.getFetch({ name: nameMaterial }).then(
        () => {
          setTypeAlert("success");
          setContent(`Se ha guardado con éxito.`);
          setOpenDialog(true);
          closeView();
        },
        (error) => {
          setTypeAlert("error");
          setContent(`Se ha producido un error al guardar.`);
          setOpenDialog(true);
          closeView();
        }
      );
    }
  };

  const closeView = () => {
    onResetForm();
    refreshDataMaterials();
    props.setOpen(false);
  };

  const onResetForm = () => {
    props.setMaterialsEditing(0);
    setNameMaterial("");
  };

  return (
    <>
      <AlertDialog
        type={typeAlert}
        content={content}
        open={openDialog}
        handleClose={handleCloseDialog}
      />

      <ModalMaterial open={props.open} onClose={handleClose} closeAfterTransition>
        <Fade in={props.open}>
          <div className="modal-container">
            <ModalLayout
              title={
                props.materialsEditing
                  ? "Editar de materiales"
                  : "Alta de materiales"
              }
              width={"60%"}
              handleClose={handleClose}
            >
              <TabPanel>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={2} sx={{ minHeight: 100 }}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <TextField
                        label="Nombre"
                        type="text"
                        placeholder=""
                        fullWidth
                        name="name"
                        value={nameMaterial}
                        onChange={(e) => setNameMaterial(e.target.value)}
                        error={false}
                        helperText={false}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <Grid
                container
                spacing={2}
                sx={{ mb: 2, mt: 1 }}
                style={{ bottom: "5%" }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    disabled={false}
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: '#afafaf', color: 'white' }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!isValid()}
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={onSubmit}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </ModalLayout>
          </div>
        </Fade>
      </ModalMaterial>
    </>
  );
};
