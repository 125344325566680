import {
  Button,
  List,
  Paper,
} from "@mui/material";
import { TextField, Grid } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { KeyHandles } from "shared/handlers/KeyHandles";
import { RoleEnum } from "shared/enums/roleEnum";
import { useEffect } from "react";

export const TodoList = ({ materialsDetails, setMaterialsDetails, role }) => {
  const updateList = (material, index) => {
    let items = [...materialsDetails];
    items[index] = material;
    setMaterialsDetails(items);
  };



  const showError = (field, index, isValidWithZero = false) => {

    let error = false;
    if (!isValidWithZero) {
      error = (materialsDetails[index] && (!field || field == '0'))
    }

    if (isValidWithZero) {
      error = (materialsDetails[index] && (!field && field != '0'))
    }
    return error;
  };
  const onRemove = (index) => {
    let items = [...materialsDetails];
    items.splice(index, 1);
    setMaterialsDetails(items);
  };


  return (
    <div>
      <Paper>
        <List style={{ overflowY: "scroll", height: "45vh" }}>
          {materialsDetails.map((material, index) => {
            return (
              <Grid container spacing={1} key={material.id} style={{ padding: "15px" }}>
                <Grid item xs={12} sm={12} md={2.5}>
                  <TextField
                    type="text"
                    disabled={true}
                    value={material.materialName}
                    name="nameMaterial"
                    label="Producto"
                    fullWidth
                  />
                </Grid>
                {material.unit_of_measure !== "pz" && (
                  <Grid item xs={12} sm={12} md={1.5}>
                    <TextField
                      type="text"
                      onChange={(e) =>
                        updateList({ ...material, ticket: e.target.value }, index)
                      }
                      value={material.ticket}
                      name="folioTicket"
                      label="Folio ticket"
                      fullWidth
                      error={showError(material?.ticket, index, true)}
                      onKeyDown={KeyHandles().keyDownOnlyNumberHandle}
                    />
                  </Grid>

                )}




                <Grid item xs={12} sm={12} md={1}>
                  <TextField
                    type="number"
                    onChange={(e) =>
                      updateList({
                        ...material,
                        weight: e.target.value < 0 ? 0 : e.target.value,
                        subtotal: (e.target.value < 0 ? 0 : e.target.value * material.price).toString(),
                      }, index)
                    }
                    value={material.weight}
                    name="weight"
                    label="Cantidad"
                    fullWidth
                    error={showError(material?.weight, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={1}>
                  <TextField
                    type="number"
                    disabled={role === RoleEnum.Usuario}
                    onChange={(e) =>
                      updateList({
                        ...material,
                        price: e.target.value < 0 ? 0 : e.target.value,
                        subtotal: (e.target.value < 0 ? 0 : e.target.value * material.weight).toString(),
                      }, index)
                    }
                    value={material.price}
                    name="priceMaterial"
                    label="Precio"
                    fullWidth
                    error={showError(material?.price, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={1.5}>
                  <TextField
                    type="number"
                    onChange={(e) =>
                      updateList({
                        ...material,
                        control_number: e.target.value < 0 ? 0 : e.target.value,
                      }, index)
                    }
                    value={material.control_number}
                    name="control_number"
                    label="Número control"
                    fullWidth
                    error={showError(material?.control_number, index, true)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3.5}>
                  <TextField
                    type="text"
                    onChange={(e) =>
                      updateList({ ...material, notes: e.target.value }, index)
                    }
                    value={material.notes}
                    name="notes"
                    label="Notas"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    onClick={() => onRemove(index)}
                    variant="contained"
                    color="secondary"
                  >
                    <DeleteForever style={{ color: "white" }} />
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};
